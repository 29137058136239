import { BarChart2, Target, Timer } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

import { Button } from "../../../JobsPage/components/Button";

const features = [
	{
		icon: <Timer className="text-primary-600" />,
		title: "Reduce Time-to-Hire",
		description:
			"From Manual sourcing, screening, and shortlisting that delays hiring decisions. To Automated processes that fill roles faster.",
	},
	{
		icon: <BarChart2 className="text-primary-600" />,
		title: "Data-Driven Decisions",
		description:
			"From Guesswork and inconsistent candidate evaluation. To Analytics and insights that help predict candidate success and reduce bad hires.",
	},
	{
		icon: <Target className="text-primary-600" />,
		title: "Eliminate Wasted Effort",
		description:
			"From Spending energy on inefficient processes. To Focusing on the best candidates, maximizing your recruitment efficiency.",
	},
];

function ValueProposition() {
	return (
		<section className="value-proposition">
			<div className="value-proposition__container">
				<div className="value-proposition__grid">
					{/* Left column - Image */}
					<div className="value-proposition__image-wrapper">
						<div className="value-proposition__image-gradient" />
						<img
							src="/images/profiles/tanzania-office.png"
							alt="Hiring Dashboard"
							className="value-proposition__image"
						/>
					</div>

					{/* Right column - Content */}
					<div className="value-proposition__content">
						<h2 className="value-proposition__title">Dproz Empowers You to Hire Smarter, Faster, and Fairer.</h2>

						<div className="value-proposition__features">
							{features.map((feature, index) => (
								<div key={index} className="value-proposition__feature">
									<div className="value-proposition__feature-icon">{feature.icon}</div>
									<div className="value-proposition__feature-content">
										<h3 className="value-proposition__feature-title">{feature.title}</h3>
										<p className="value-proposition__feature-description">{feature.description}</p>
									</div>
								</div>
							))}
						</div>

						<div className="value-proposition__cta">
							<Link href="/signup">
								<Button variant="default" size="lg">
									Get Started
								</Button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default ValueProposition;
