import { motion } from "framer-motion";
import React from "react";

function DashboardVideo({ videoUrl, posterImage }) {
	return (
		<motion.div
			className="relative w-full max-w-[1000px] mx-auto"
			initial={{ opacity: 0, y: 20 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{
				duration: 0.8,
				ease: "easeOut",
			}}
		>
			{/* Dashboard frame with double border effect */}
			<div className="relative w-full rounded-[1.5rem] border-[8px] border-white/20 backdrop-blur-lg bg-white/15 shadow-[0_0_15px_rgba(0,0,0,0.05),0_25px_50px_-12px_rgba(0,0,0,0.15)]">
				{/* Gray border gradient overlay */}
				<div className="absolute -inset-[1px] rounded-[calc(1.4rem-1px)] bg-gradient-to-b from-white/60 via-white/30 to-white/10 backdrop-blur-xl mix-blend-soft-light pointer-events-none shadow-inner" />

				{/* Header bar */}
				<div className="h-10 bg-white border-b border-gray-200 rounded-t-2xl flex items-center px-4">
					<div className="flex space-x-2">
						<div className="w-3 h-3 rounded-full bg-gray-300" />
						<div className="w-3 h-3 rounded-full bg-gray-300" />
						<div className="w-3 h-3 rounded-full bg-gray-300" />
					</div>
				</div>

				{/* Content area */}
				<div className="bg-white rounded-b-xl overflow-hidden">
					<div className="relative pt-[60%] overflow-hidden">
						<video
							className="absolute inset-0 w-full h-full object-cover"
							autoPlay
							loop
							muted
							playsInline
							poster={posterImage}
						>
							<source src={videoUrl} type="video/mp4" />
							Your browser does not support the video tag.
						</video>
					</div>
				</div>
			</div>
		</motion.div>
	);
}

export default DashboardVideo;
