import React from "react";
import { useNavigate } from "react-router-dom";

import { cn } from "../../../handlers/cn.utils";
import Loader from "../../StaticPages/HomePage/components/Loader";
import { Card } from "./Card";

function Categories({ categories, setSelectedCategory, selectedCategory, loadingCategories }) {
	const navigate = useNavigate();

	return (
		<Card className="p-4">
			<h3 className="text-sm font-medium text-gray-900 font-inter mb-3">Browse by Category</h3>
			{loadingCategories && (
				<div className="mt-5">
					<Loader />
				</div>
			)}
			{!loadingCategories && (
				<div className="space-y-1">
					{categories.slice(0, 20).map((cat) => (
						<button
							key={cat._id}
							className={cn(
								"ignore-focus-behavior w-full text-left px-3 py-2 rounded-md transition-colors duration-200 flex items-center justify-between group",
								selectedCategory === cat._id
									? "bg-primary-50 text-primary-700 hover:bg-primary-100"
									: "text-gray-700 hover:bg-gray-50 hover:text-gray-900",
							)}
							onClick={() => {
								setSelectedCategory(cat._id);
								navigate("/jobs?category=" + cat._id);
							}}
						>
							<div className="flex items-center">
								<img
									alt={`${cat} icon"`}
									loading="lazy"
									width="18"
									height="18"
									decoding="async"
									data-nimg="1"
									className="mr-2 opacity-75"
									src={`/images/landing/${cat._id}.svg`}
								/>
								<span className="text-sm font-medium">{cat._id}</span>
							</div>
							<span className="text-xs text-gray-500 tabular-nums">{cat.count}</span>
						</button>
					))}
				</div>
			)}
		</Card>
	);
}

export default Categories;
