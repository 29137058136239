import React, { useState } from "react";

const MediaKitPage = () => {
	const isDprozOpsUser = localStorage.getItem("isDropzOpsUser");

	if (!isDprozOpsUser) {
		window.location.href = "/login";
	}

	const [copiedColor, setCopiedColor] = useState(null);

	const handleDownload = (path) => {
		window.open(path, "_blank");
	};

	const colors = {
		blue: {
			50: "#eff6ff",
			100: "#dbeafe",
			200: "#bfdbfe",
			300: "#93c5fd",
			400: "#60a5fa",
			500: "#3b82f6",
			600: "#2563eb",
			700: "#1d4ed8",
			800: "#1e40af",
			900: "#1e3a8a",
		},
		gray: {
			50: "#f9fafb",
			100: "#f3f4f6",
			200: "#e5e7eb",
			300: "#d1d5db",
			400: "#9ca3af",
			500: "#6b7280",
			600: "#4b5563",
			700: "#374151",
			800: "#1f2937",
			900: "#111827",
		},
	};

	const handleCopyColor = (hex) => {
		navigator.clipboard.writeText(hex);
		setCopiedColor(hex);
		setTimeout(() => {
			setCopiedColor(null);
		}, 1500);
	};

	return (
		<main className="min-h-screen">
			<div className="min-h-screen bg-white">
				{/* Hero Section */}
				<div className="relative bg-gray-900 border-b border-gray-800">
					<div className="absolute inset-0">
						<img
							src="/images/pattern.svg"
							alt="Background Pattern"
							className="object-cover opacity-30 mix-blend-soft-light [transform:scale(0.85)] [background-repeat:repeat]"
						/>
					</div>
					<div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
						<h1 className="text-4xl font-bold text-white mb-3">Media Kit</h1>
						<p className="text-xl text-gray-300">Official branding assets and guidelines for Dproz</p>
					</div>
				</div>

				{/* Color Palette Section */}
				<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 border-b">
					<h2 className="text-2xl font-semibold text-gray-900 mb-8">Color Palette</h2>

					<div className="space-y-8">
						{/* Blue Colors */}
						<div>
							<h3 className="text-[12px] font-medium mb-3 uppercase tracking-[0.05em] text-gray-700">Primary Blue</h3>
							<div className="grid grid-cols-5 md:grid-cols-10 gap-2">
								{Object.entries(colors.blue).map(([shade, hex]) => (
									<button
										key={shade}
										onClick={() => handleCopyColor(hex)}
										className="group relative aspect-square rounded-md overflow-hidden"
										style={{ backgroundColor: hex }}
									>
										<div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 bg-black/50 transition-opacity">
											<span className="text-xs text-white font-medium">{copiedColor === hex ? "Copied!" : hex}</span>
										</div>
									</button>
								))}
							</div>
						</div>

						{/* Gray Colors */}
						<div>
							<h3 className="text-[12px] font-medium mb-3 uppercase tracking-[0.05em] text-gray-700">Neutral Gray</h3>
							<div className="grid grid-cols-5 md:grid-cols-10 gap-2">
								{Object.entries(colors.gray).map(([shade, hex]) => (
									<button
										key={shade}
										onClick={() => handleCopyColor(hex)}
										className="group relative aspect-square rounded-md overflow-hidden"
										style={{ backgroundColor: hex }}
									>
										<div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 bg-black/50 transition-opacity">
											<span className="text-xs text-white font-medium">{copiedColor === hex ? "Copied!" : hex}</span>
										</div>
									</button>
								))}
							</div>
						</div>
					</div>
				</div>

				{/* Logo Variants Section */}
				<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
					<h2 className="text-2xl font-semibold text-gray-900 mb-8">Logo Variants</h2>

					<div className="space-y-4 mb-10 max-w-3xl">
						<p className="text-base leading-relaxed text-gray-700">
							The connected, flowing style of the letters in the logo are meant to visually represent the connection
							between job seekers and employers that the platform facilitates.
						</p>
						<p className="text-base leading-relaxed text-gray-700">
							The rounded, soft edges convey a sense of approachability and user-friendliness, and a welcoming platform
							for both candidates and recruiters.
						</p>
						<p className="text-base leading-relaxed text-gray-700">
							The logo aims to emphasize Dproz&#39;s role as a connector in the Tanzanian job market - creating fluid,
							straightforward pathways between talent and opportunities.
						</p>
					</div>

					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
						{/* Light Background Logo */}
						<div>
							<h3 className="text-[12px] font-medium mb-3 uppercase tracking-[0.05em] text-gray-700">
								Logo on Light Background
							</h3>
							<div className="p-8 bg-white rounded-lg shadow-sm border">
								<div className="aspect-video bg-white flex items-center justify-center mb-4">
									<img
										src="/media-kit/logos/logo-light.svg"
										alt="Logo on Light Background"
										width={224}
										height={80}
										className="w-56 h-20 object-contain"
									/>
								</div>
								<div className="flex flex-col space-y-3">
									<div className="flex space-x-3">
										<button
											onClick={() => handleDownload("/media-kit/logos/logo-light.svg")}
											className="flex-1 inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
										>
											Download SVG
										</button>
										<button
											onClick={() => handleDownload("/media-kit/logos/logo-light.png")}
											className="flex-1 inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50"
										>
											Download PNG
										</button>
									</div>
									<div className="text-xs text-gray-500">
										<span className="font-medium">Recommended for:</span> White or light colored backgrounds
									</div>
								</div>
							</div>
						</div>

						{/* Dark Background Logo */}
						<div>
							<h3 className="text-[12px] font-medium mb-3 uppercase tracking-[0.05em] text-gray-700">
								Logo on Dark Background
							</h3>
							<div className="p-8 bg-gray-900 rounded-lg shadow-sm">
								<div className="aspect-video bg-gray-900 flex items-center justify-center mb-4">
									<img
										src="/media-kit/logos/logo-dark.svg"
										alt="Logo on Dark Background"
										width={224}
										height={80}
										className="w-56 h-20 object-contain"
									/>
								</div>
								<div className="flex flex-col space-y-3">
									<div className="flex space-x-3">
										<button
											onClick={() => handleDownload("/media-kit/logos/logo-dark.svg")}
											className="flex-1 inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-gray-900 bg-white hover:bg-gray-50"
										>
											Download SVG
										</button>
										<button
											onClick={() => handleDownload("/media-kit/logos/logo-dark.png")}
											className="flex-1 inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50"
										>
											Download PNG
										</button>
									</div>
									<div className="text-xs text-gray-400">
										<span className="font-medium">Recommended for:</span> Dark colored backgrounds
									</div>
								</div>
							</div>
						</div>

						{/* Grayscale Logo */}
						<div>
							<h3 className="text-[12px] font-medium mb-3 uppercase tracking-[0.05em] text-gray-700">Grayscale Logo</h3>
							<div className="p-8 bg-white rounded-lg shadow-sm border">
								<div className="aspect-video bg-white flex items-center justify-center mb-4">
									<img
										src="/media-kit/logos/logo-grayscale.svg"
										alt="Grayscale Logo"
										width={224}
										height={80}
										className="w-56 h-20 object-contain"
									/>
								</div>
								<div className="flex flex-col space-y-3">
									<div className="flex space-x-3">
										<button
											onClick={() => handleDownload("/media-kit/logos/logo-grayscale.svg")}
											className="flex-1 inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
										>
											Download SVG
										</button>
										<button
											onClick={() => handleDownload("/media-kit/logos/logo-grayscale.png")}
											className="flex-1 inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50"
										>
											Download PNG
										</button>
									</div>
									<div className="text-xs text-gray-500">
										<span className="font-medium">Recommended for:</span> Monochrome applications
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* Social Media Assets Section */}
				<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 border-t">
					<h2 className="text-2xl font-semibold text-gray-900 mb-8">Social Media Assets</h2>

					<div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
						{/* Profile Picture - Light Background */}
						<div className="bg-white rounded-lg shadow-sm border p-6">
							<div className="aspect-square bg-white mb-4 flex items-center justify-center">
								<img
									src="/media-kit/social/profile-light.png"
									alt="Profile Picture - Light Background"
									width={400}
									height={400}
									className="w-full h-full object-contain"
								/>
							</div>
							<h3 className="text-lg font-medium mb-2 font-inter">Profile Picture</h3>
							<p className="text-sm text-gray-600 mb-4">For use on light backgrounds across social media platforms</p>
							<div className="flex space-x-3">
								<button
									onClick={() => handleDownload("/media-kit/social/profile-light.png")}
									className="flex-1 inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50"
								>
									Download PNG
								</button>
							</div>
						</div>

						{/* Profile Picture - Dark Background */}
						<div className="bg-white rounded-lg shadow-sm border p-6">
							<div className="aspect-square bg-gray-900 mb-4 flex items-center justify-center">
								<img
									src="/media-kit/social/profile-dark.png"
									alt="Profile Picture - Dark Background"
									width={400}
									height={400}
									className="w-full h-full object-contain"
								/>
							</div>
							<h3 className="text-lg font-medium mb-2 font-inter">Profile Picture</h3>
							<p className="text-sm text-gray-600 mb-4">For use on dark backgrounds across social media platforms</p>
							<div className="flex space-x-3">
								<button
									onClick={() => handleDownload("/media-kit/social/profile-dark.png")}
									className="flex-1 inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50"
								>
									Download PNG
								</button>
							</div>
						</div>

						{/* Facebook Cover */}
						<div className="bg-white rounded-lg shadow-sm border p-6">
							<div className="aspect-[820/312] bg-gray-100 mb-4 flex items-center justify-center overflow-hidden">
								<img
									src="/media-kit/social/facebook-cover.png"
									alt="Facebook Cover"
									width={820}
									height={312}
									className="w-full h-full object-contain"
								/>
							</div>
							<h3 className="text-lg font-medium mb-2 font-inter">Facebook Cover Image</h3>
							<p className="text-sm text-gray-600 mb-4">Optimized for Facebook business page cover</p>
							<div className="flex space-x-3">
								<button
									onClick={() => handleDownload("/media-kit/social/facebook-cover.png")}
									className="flex-1 inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50"
								>
									Download PNG
								</button>
							</div>
						</div>

						{/* LinkedIn Cover */}
						<div className="bg-white rounded-lg shadow-sm border p-6">
							<div className="aspect-[1584/396] bg-gray-100 mb-4 flex items-center justify-center overflow-hidden">
								<img
									src="/media-kit/social/linkedin-cover.png"
									alt="LinkedIn Cover"
									width={1584}
									height={396}
									className="w-full h-full object-contain"
								/>
							</div>
							<h3 className="text-lg font-medium mb-2 font-inter">LinkedIn Cover Image</h3>
							<p className="text-sm text-gray-600 mb-4">Optimized for LinkedIn company page banner</p>
							<div className="flex space-x-3">
								<button
									onClick={() => handleDownload("/media-kit/social/linkedin-cover.png")}
									className="flex-1 inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50"
								>
									Download PNG
								</button>
							</div>
						</div>
					</div>
				</div>

				{/* Skillset Icons Section */}
				<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 border-t">
					<h2 className="text-2xl font-semibold text-gray-900 mb-8">Skillset Icons</h2>

					<div className="space-y-8">
						<div>
							<div className="grid grid-cols-4 md:grid-cols-8 gap-x-6 gap-y-8">
								{[
									{ name: "Accounting", file: "icon-accounting.svg" },
									{ name: "Administration", file: "icon-admin.svg" },
									{ name: "Advertising", file: "icon-advertising.svg" },
									{ name: "Agriculture", file: "icon-agriculture.svg" },
									{ name: "Banking", file: "icon-banking.svg" },
									{
										name: "Civil Engineering",
										file: "icon-civil-engineering.svg",
									},
									{ name: "Community Services", file: "icon-community.svg" },
									{ name: "Consulting", file: "icon-consulting.svg" },
									{ name: "Customer Service", file: "icon-customer-service.svg" },
									{ name: "Design", file: "icon-design.svg" },
									{ name: "Education", file: "icon-education.svg" },
									{ name: "Engineering", file: "icon-engineering.svg" },
									{ name: "Finance", file: "icon-finance.svg" },
									{ name: "Healthcare", file: "icon-healthcare.svg" },
									{ name: "Hospitality", file: "icon-hospitality.svg" },
									{ name: "Human Resources", file: "icon-human-resources.svg" },
									{ name: "IT", file: "icon-it.svg" },
									{ name: "Insurance", file: "icon-insurance.svg" },
									{ name: "Legal", file: "icon-legal.svg" },
									{ name: "Marketing", file: "icon-marketing.svg" },
									{ name: "Operations", file: "icon-operations.svg" },
									{ name: "Real Estate", file: "icon-real-estate.svg" },
									{ name: "Retail", file: "icon-retail.svg" },
									{ name: "Sales", file: "icon-sales.svg" },
									{ name: "Security", file: "icon-security.svg" },
									{ name: "Sports", file: "icon-sports.svg" },
									{ name: "Telecommunications", file: "icon-telecom.svg" },
									{ name: "Transport", file: "icon-transport.svg" },
								].map((icon) => (
									<div key={icon.file} className="flex flex-col items-center gap-1.5">
										<div className="w-16 h-16 bg-gray-50 rounded-xl p-3 flex items-center justify-center">
											<img
												src={`/icons/skillsets/${icon.file}`}
												alt={icon.name}
												width={48}
												height={48}
												className="w-full h-full object-contain"
											/>
										</div>
										<span className="text-xs text-center font-medium text-gray-700">{icon.name}</span>
										<button
											onClick={() => handleDownload(`/icons/skillsets/${icon.file}`)}
											className="text-xs font-medium text-gray-600 hover:text-gray-900 transition-colors"
										>
											Download SVG
										</button>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>

				{/* Brand Guidelines Section */}
				<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 border-t">
					<h2 className="text-2xl font-semibold text-gray-900 mb-8">Brand Guidelines</h2>
					<div className="prose max-w-none">
						<p className="text-gray-600">Please follow these guidelines when using our brand assets:</p>
						<ul className="list-disc pl-5 text-gray-600">
							<li>Do not modify or alter the logos in any way</li>
							<li>Maintain proper spacing around the logo</li>
							<li>Use the appropriate logo version based on the background</li>
							<li>Do not use the logo on busy backgrounds or patterns</li>
							<li>For digital use, prefer PNG format for best quality</li>
							<li>For print or scalable needs, use the SVG format</li>
							<li>Use grayscale version only when color reproduction is not possible</li>
						</ul>
					</div>
				</div>
			</div>
		</main>
	);
};

export default MediaKitPage;
