import React from "react";

import { ChevronRightIcon } from "../../../../assets/icons/interface/interface";

const features = [
	{
		title: "Post Jobs",
		company: "From Creating and publishing job listings manually.",
		description: "To Leveraging AI-powered templates and smart suggestions to post jobs in minutes.",
		image: "/images/features/feature-01.png",
	},
	{
		title: "Search Resumes",
		company: "From Scouring through unorganized databases or spreadsheets.",
		description: "To Accessing a curated database of pre-screened candidates with intelligent matching algorithms.",
		image: "/images/features/feature-02.png",
	},
	{
		title: "Track Applications",
		company: "From Using disparate systems to monitor applications, leading to confusion.",
		description: "To A full pipeline view with automated screening and ranking—all in one place.",
		image: "/images/features/feature-03.png",
	},
];

function FeaturesShowcase() {
	return (
		<section className="features-showcase">
			<div className="features-showcase__container">
				{/* Title section */}
				<div className="features-showcase__content">
					<h2 className="features-showcase__title">Powerful Hiring Tools at Your Fingertips</h2>
					<p className="features-showcase__description">
						Everything you need to streamline your recruitment process and find the best talent
					</p>
				</div>

				{/* Feature cards */}
				<div className="features-showcase__cards">
					{features.map((feature, index) => (
						<div key={index} className="showcase-card">
							<div className="showcase-card__image">
								<img src={feature.image} alt={feature.title} className="object-cover" />
							</div>
							<div className="showcase-card__content">
								<div className="showcase-card__header">
									<h3 className="showcase-card__title">{feature.title}</h3>
									<p className="showcase-card__client">{feature.company}</p>
								</div>

								<p className="showcase-card__description">{feature.description}</p>

								<div className="showcase-card__learn-more">
									<div className="showcase-card__learn-more-wrapper">
										<div className="showcase-card__learn-more-button">
											<ChevronRightIcon className="showcase-card__learn-more-icon" />
											<span className="showcase-card__learn-more-text">Learn more</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
}

export default FeaturesShowcase;
