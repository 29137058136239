import React from "react";

import { BriefcaseIcon, CheckIcon, MapPinIcon } from "../../../../assets/icons/interface/interface";
import { wrapLinksAndEmails } from "../../../../handlers/valueTransformation";
// import { BriefcaseIcon, CheckIcon, DollarSign, MapPinIcon } from "../../../../assets/icons/interface/interface";
// import { Checkbox } from "../../../JobsPage/components/Checkbox";
// import { Input } from "../../../JobsPage/components/Input";
// import { Label } from "../../../JobsPage/components/Label";
// import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../JobsPage/components/Select";
// import { Textarea } from "../../../JobsPage/components/TextArea";
import { Modal } from "../../../Profile/components/Modal";

// const initialFormData = {
// 	fullName: "",
// 	email: "",
// 	password: "",
// 	confirmPassword: "",
// 	location: "",
// 	isRemote: false,
// 	yearsOfExperience: "",
// 	desiredSalary: "",
// 	interests: "",
// };

const SAMPLE_DATA = {
	visaSponsorship: "Available",
	recruiterName: "Sarah Johnson",
	recruiterTitle: "Senior Technical Recruiter",
	recruiterInfo: "I help connect talented engineers with exciting opportunities at fast-growing companies.",
	jobType: "Full-time",
	location: "Dar es Salaam",
	perks: ["Competitive salary", "Health insurance", "Remote work options", "Professional development"],
	yearsOfExperience: [
		{ value: "0-1", label: "Less than 1 year" },
		{ value: "1-3", label: "1 to 3 years" },
		{ value: "3-5", label: "3 to 5 years" },
		{ value: "5+", label: "More than 5 years" },
	],
};

const ApplyModal = ({
	isOpen,
	onClose,
	jobTitle,
	companyName,
	companyLogo,
	applicationDetails,
	applicationProcess,
	onDrawer = false,
	location = SAMPLE_DATA.location,
	jobType = SAMPLE_DATA.jobType,
	// visaSponsorship = SAMPLE_DATA.visaSponsorship,
	recruiterName = SAMPLE_DATA.recruiterName,
}) => {
	// const [formData, setFormData] = React.useState(initialFormData);

	// const handleInputChange = (e) => {
	// 	const { name, value } = e.target;
	// 	setFormData((prev) => ({ ...prev, [name]: value }));
	// };
	//
	// const handleSubmit = (e) => {
	// 	e.preventDefault();
	// 	console.log("Form submitted:", formData);
	// 	onClose();
	// };
	return (
		<Modal
			isOpen={isOpen}
			size="medium-large"
			onClose={onClose}
			onDrawer={onDrawer && applicationDetails.applicationType !== "URL"}
			title={`Apply to ${jobTitle}`}
			variant="split"
			secondaryAction={
				applicationDetails.applicationType === "URL"
					? {
							label: "Continue",
							onClick: () => {
								window.open(applicationDetails.link, "_blank");
								onClose();
							},
						}
					: null
			}
			rightContent={
				<div className="h-[calc(100vh-64px)] overflow-y-auto" style={{ zIndex: -100 }}>
					<div className="space-y-8 p-4">
						{/* Company Info */}
						<div className="flex items-center space-x-4">
							<img
								src={companyLogo}
								alt={`${companyName} logo`}
								width={56}
								height={56}
								className="w-14 h-14 rounded-lg object-contain"
								onError={(e) => (e.target.src = "/images/co-placeholder.png")}
							/>
							<div>
								<h3 className="font-semibold text-xl text-gray-900">{companyName}</h3>
								<p className="text-base text-gray-700">{jobTitle}</p>
							</div>
						</div>

						{/* Job Details */}
						<div className="space-y-3">
							<div className="flex items-center space-x-2">
								<MapPinIcon className="w-5 h-5 text-gray-600" />
								<span className="text-gray-700 text-sm">{location}</span>
							</div>
							<div className="flex items-center space-x-2">
								<BriefcaseIcon className="w-5 h-5 text-gray-600" />
								<span className="text-gray-700 text-sm">{jobType}</span>
							</div>
						</div>

						{/* Benefits & Perks */}
						<div>
							<h4 className="font-medium text-gray-900 text-base mb-3">Benefits & Perks</h4>
							<ul className="space-y-2">
								{SAMPLE_DATA.perks.map((perk, index) => (
									<li key={index} className="flex items-center space-x-2">
										<CheckIcon className="w-4 h-4 text-green-500" />
										<span className="text-gray-700 text-sm">{perk}</span>
									</li>
								))}
							</ul>
						</div>

						{/* Recruiter Info */}
						<div className="border-t pt-4">
							<h4 className="font-medium text-gray-900 text-sm mb-2">Your Recruiter</h4>{" "}
							{/* Changed from text-lg to text-sm */}
							<div className="space-y-2">
								<p className="text-base font-medium text-gray-900">{recruiterName}</p>
								<p className="text-sm text-gray-700">{SAMPLE_DATA.recruiterTitle}</p>
								<p className="text-sm text-gray-600">{SAMPLE_DATA.recruiterInfo}</p>
							</div>
						</div>
					</div>
				</div>
			}
		>
			<div className="overflow-y-auto">
				<div className="">
					<div className="pb-3 max-h-[calc(100vh-200px)] overflow-y-auto pr-2">
						<div className="space-y-3 mb-3">
							{applicationDetails.applicationType === "URL" ? (
								<>
									<h3 className="text-[14px] font-medium font-inter text-gray-950">You&#39;re Leaving Dproz.</h3>
									<h3 className="text-[14px] font-medium font-inter text-gray-950">
										This Job application will take you to an external site. Ensure you follow the instructions on the
										site to complete your application. Your progress on Dproz will not be affected.
									</h3>
								</>
							) : (
								<>
									<h3 className="text-[14px] font-medium font-inter text-gray-950">Job Application Instructions</h3>
									<h3
										className="text-[14px] font-medium font-inter text-gray-950"
										dangerouslySetInnerHTML={{
											__html: wrapLinksAndEmails(applicationProcess),
										}}
									/>
								</>
							)}
						</div>
						{/*<form onSubmit={handleSubmit} className="space-y-4"> /!* Changed from space-y-6 to space-y-4 *!/*/}
						{/*	<div>*/}
						{/*		<Label htmlFor="fullName" className="text-sm">Full Name*</Label>*/}
						{/*		<Input*/}
						{/*			id="fullName"*/}
						{/*			name="fullName"*/}
						{/*			value={formData.fullName}*/}
						{/*			onChange={handleInputChange}*/}
						{/*			required*/}
						{/*			className="mt-1.5 placeholder:text-gray-600" // Added darker placeholder text*/}
						{/*			placeholder="Enter your full name"*/}
						{/*		/>*/}
						{/*	</div>*/}

						{/*	<div>*/}
						{/*		<Label htmlFor="email" className="text-sm">Email Address*</Label>*/}
						{/*		<Input*/}
						{/*			id="email"*/}
						{/*			name="email"*/}
						{/*			type="email"*/}
						{/*			value={formData.email}*/}
						{/*			onChange={handleInputChange}*/}
						{/*			required*/}
						{/*			className="mt-1.5 placeholder:text-gray-600" // Added darker placeholder text*/}
						{/*			placeholder="you@example.com"*/}
						{/*		/>*/}
						{/*	</div>*/}

						{/*	<div>*/}
						{/*		<Label htmlFor="password" className="text-sm">Create Password*</Label>*/}
						{/*		<Input*/}
						{/*			id="password"*/}
						{/*			name="password"*/}
						{/*			type="password"*/}
						{/*			value={formData.password}*/}
						{/*			onChange={handleInputChange}*/}
						{/*			required*/}
						{/*			minLength={8}*/}
						{/*			placeholder="Minimum 8 characters"*/}
						{/*			className="mt-1.5 placeholder:text-gray-600" // Added darker placeholder*/}
						{/*		/>*/}
						{/*	</div>*/}

						{/*	<div>*/}
						{/*		<Label htmlFor="confirmPassword" className="text-sm">Confirm Password*</Label>*/}
						{/*		<Input*/}
						{/*			id="confirmPassword"*/}
						{/*			name="confirmPassword"*/}
						{/*			type="password"*/}
						{/*			value={formData.confirmPassword}*/}
						{/*			onChange={handleInputChange}*/}
						{/*			required*/}
						{/*			placeholder="repeat password"*/}
						{/*			className="mt-1.5 placeholder:text-gray-600" // Added darker placeholder*/}
						{/*		/>*/}
						{/*	</div>*/}

						{/*	<div>*/}
						{/*		<Label htmlFor="location" className="text-sm">Set Your Location*</Label>*/}
						{/*		<Input*/}
						{/*			id="location"*/}
						{/*			name="location"*/}
						{/*			value={formData.location}*/}
						{/*			onChange={handleInputChange}*/}
						{/*			required*/}
						{/*			placeholder="e.g. San Francisco"*/}
						{/*			className="mt-1.5 placeholder:text-gray-600" // Added darker placeholder*/}
						{/*		/>*/}
						{/*	</div>*/}

						{/*	<div className="flex items-center space-x-3 pt-1"> /!* Changed from pt-2 to pt-1 *!/*/}
						{/*		<Checkbox*/}
						{/*			id="isRemote"*/}
						{/*			checked={formData.isRemote}*/}
						{/*			onCheckedChange={(checked) => setFormData((prev) => ({ ...prev, isRemote: checked }))}*/}
						{/*		/>*/}
						{/*		<Label htmlFor="isRemote" className="text-gray-700 text-sm">*/}
						{/*			I&#39;m interested in remote work opportunities*/}
						{/*		</Label>*/}
						{/*	</div>*/}

						{/*	<div className="grid grid-cols-2 gap-4 pt-1"> /!* Added pt-1 for consistency *!/*/}
						{/*		<div>*/}
						{/*			<Label htmlFor="yearsOfExperience" className="text-sm">Years of experience*</Label>*/}
						{/*			<Select*/}
						{/*				value={formData.yearsOfExperience}*/}
						{/*				onValueChange={(value) => setFormData((prev) => ({ ...prev, yearsOfExperience: value }))}*/}
						{/*			>*/}
						{/*				<SelectTrigger id="yearsOfExperience" className="mt-1.5">*/}
						{/*					<SelectValue placeholder="Select years"*/}
						{/*											 className="text-gray-600" /> /!* Updated placeholder color *!/*/}
						{/*				</SelectTrigger>*/}
						{/*				<SelectContent>*/}
						{/*					{SAMPLE_DATA.yearsOfExperience.map(option => (*/}
						{/*						<SelectItem key={option.value} value={option.value}*/}
						{/*												className="text-gray-700">{option.label}</SelectItem>*/}
						{/*					))}*/}
						{/*				</SelectContent>*/}
						{/*			</Select>*/}
						{/*		</div>*/}
						{/*		<div>*/}

						{/*			<Label htmlFor="desiredSalary" className="text-sm">Desired Salary*</Label>*/}
						{/*			<div className="relative mt-1.5">*/}
						{/*				<DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-600" />*/}
						{/*				<Input*/}
						{/*					id="desiredSalary"*/}
						{/*					name="desiredSalary"*/}
						{/*					type="number"*/}
						{/*					value={formData.desiredSalary}*/}
						{/*					onChange={handleInputChange}*/}
						{/*					placeholder="enter amount in USD"*/}
						{/*					required*/}
						{/*					className="pl-10 placeholder:text-gray-600" // Added darker placeholder*/}
						{/*				/>*/}
						{/*			</div>*/}
						{/*		</div>*/}
						{/*	</div>*/}

						{/*	<div className="pt-1"> /!* Changed from no padding to pt-1 *!/*/}
						{/*		<Label htmlFor="interests" className="text-sm">Why are you interested in {companyName}?</Label>*/}
						{/*		<p className="text-sm text-gray-600 mt-1 mb-1.5">Tell us what excites you about this role and company</p>*/}
						{/*		<Textarea*/}
						{/*			id="interests"*/}
						{/*			name="interests"*/}
						{/*			value={formData.interests}*/}
						{/*			onChange={handleInputChange}*/}
						{/*			rows={4}*/}
						{/*			className="mt-1.5 placeholder:text-gray-600" // Added darker placeholder*/}
						{/*			placeholder=""*/}
						{/*		/>*/}
						{/*	</div>*/}

						{/*	<div className="pt-1"> /!* Changed from pt-4 to pt-1 *!/*/}
						{/*		<Label className="text-sm">Resume/CV*</Label>*/}
						{/*		<p className="text-sm text-gray-600 mt-1 mb-2">Upload your resume or CV in PDF, DOC, DOCX, or TXT*/}
						{/*			format</p>*/}
						{/*		<div*/}
						{/*			className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 hover:border-gray-300 transition-colors duration-200">*/}
						{/*			<div className="text-center">*/}
						{/*				<svg*/}
						{/*					className="mx-auto h-12 w-12 text-gray-500" // Darker icon*/}
						{/*					viewBox="0 0 24 24"*/}
						{/*					fill="currentColor"*/}
						{/*					aria-hidden="true"*/}
						{/*				>*/}
						{/*					<path*/}
						{/*						fillRule="evenodd"*/}
						{/*						d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"*/}
						{/*						clipRule="evenodd"*/}
						{/*					/>*/}
						{/*				</svg>*/}
						{/*				<div className="mt-4 flex text-sm leading-6 text-gray-800"> /!* Darker text *!/*/}
						{/*					<label*/}
						{/*						htmlFor="file-upload"*/}
						{/*						className="relative cursor-pointer rounded-md bg-white font-semibold text-primary-700 focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-600 focus-within:ring-offset-2 hover:text-primary-600 transition-colors duration-200"*/}
						{/*					>*/}
						{/*						<span>Upload a file</span>*/}
						{/*						<input id="file-upload" name="file-upload" type="file" className="sr-only" />*/}
						{/*					</label>*/}
						{/*					<p className="pl-1">or drag and drop</p>*/}
						{/*				</div>*/}
						{/*				<p className="text-xs leading-5 text-gray-700">supports .pdf, .doc, .docx, or .txt file</p>*/}
						{/*			</div>*/}
						{/*		</div>*/}
						{/*	</div>*/}

						{/*	<Button*/}
						{/*		type="submit"*/}
						{/*		className="w-full bg-primary-700 hover:bg-primary-800 text-white py-2.5 text-base mt-6" // Changed from mt-8 to mt-6*/}
						{/*	>*/}
						{/*		Submit Application*/}
						{/*	</Button>*/}
						{/*</form>*/}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ApplyModal;
