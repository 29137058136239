import React, { useContext } from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

import AuthContext from "../../context/Auth/AuthContext";
import { getInitials } from "../../handlers/utilityFunctions";
import useIsLoggedIn from "../../hooks/use-IsLoggedIn";

function ExpertTopNav({ screenWidth, headingIcon, headingTitle, setShowSideBar }) {
	const isLoggedIn = useIsLoggedIn();
	const isDropzOpsUser = localStorage.getItem("isDropzOpsUser");
	const { firstName, lastName, email } = JSON.parse(localStorage.getItem("profileData"))
		? JSON.parse(localStorage.getItem("profileData"))
		: { firstName: "", lastName: "", email: "" };
	const { setUserData } = useContext(AuthContext);
	const [__, _, removeCookie] = useCookies(["Authorization"]);

	return (
		<div className="expert-review-top-nav">
			{screenWidth <= 768 && (
				<i
					className="bi bi-list cursor-pointer"
					style={{ color: "#484E5C", fontSize: "20px" }}
					onClick={() => setShowSideBar(true)}
				/>
			)}
			{screenWidth > 768 && (
				<div className="d-flex align-items-center">
					{headingIcon && (
						<i className={`bi bi-${headingIcon}`} style={{ color: "#3E424E", marginRight: 9, fontSize: "20px" }} />
					)}
					{headingTitle && (
						<div className="add-custom-skill-button-text" style={{ color: "#000000", fontSize: "16px" }}>
							{headingTitle}
						</div>
					)}
				</div>
			)}
			<div className="expert-review-top-nav-actions-container ml-auto">
				<div className="expert-review-top-nav-avatar">
					<div className="expert-review-top-nav-avatar-text">{getInitials(`${firstName} ${lastName}`)}</div>
				</div>
				<div className="dropdown tr-change-dropdown">
					<i
						data-toggle="dropdown"
						className="bi bi-chevron-down cursor-pointer ml-1"
						style={{ color: "#484E5C", fontSize: "12px" }}
					/>
					<ul className="dropdown-menu tr-change tr-list rounded p-1 custom-dropdown dropdown-menu-right">
						<li className="dropdown-item d-flex align-items-center px-2">
							<i className="fa fa-user mr-2" />
							<div>
								<p className="m-0 text-xs">{firstName ? `${firstName}` : "My Account "}</p>
								<h6 className="m-0 text-xs">{email.split("@")[0] + "@..."}</h6>
							</div>
							<div className="dropDownleftArrow">
								<span className="fa fa-angle-right text-primary" />
							</div>
						</li>

						<Link to="/jobs">
							<li className="dropdown-item custom-dropdown-item d-flex align-items-center">
								<i className="fas fa-folder-open  dropDownIconStyle" />
								<a className="d-flex text-dark text-dark  align-items-center">Jobs</a>
								<div className="dropDownleftArrow">
									<span className="fa fa-angle-right text-primary" />
								</div>
							</li>
						</Link>

						{isLoggedIn ? (
							<>
								<li className="dropdown-item d-flex align-items-center custom-dropdown-item dropdown-li">
									<i className="fas fa-briefcase dropDownIconStyle" />
									<Link to="/jobs-preference" className="d-flex text-dark align-items-center">
										Job Preferences
									</Link>
								</li>
								{isDropzOpsUser && (
									<>
										<li className="dropdown-item d-flex align-items-center custom-dropdown-item dropdown-li">
											<i className="bi bi-person-fill-gear dropDownIconStyle" />
											<Link to="/job-market" className="d-flex text-dark align-items-center">
												Control Center
											</Link>
										</li>
										<li className="dropdown-item d-flex align-items-center custom-dropdown-item dropdown-li">
											<i className="bi bi-easel2-fill dropDownIconStyle" />
											<Link to="/media-kit" className="d-flex text-dark align-items-center">
												Media Kit
											</Link>
										</li>
									</>
								)}
								<li className="dropdown-item d-flex align-items-center custom-dropdown-item dropdown-li">
									<i className="fa fa-sign-out dropDownIconStyle" />
									<Link
										to="/signin"
										onClick={() => {
											removeCookie("Authorization");
											localStorage.removeItem("profileData");
											setUserData(null);
											window.location.href = "/signin";
										}}
										className="d-flex text-dark  align-items-center"
									>
										Log Out
									</Link>
								</li>
							</>
						) : (
							<li>
								<Link to="/register">Register</Link>
							</li>
						)}
					</ul>
				</div>
			</div>
		</div>
	);
}

export default ExpertTopNav;
