import { ChevronRightIcon } from "lucide-react";
import React from "react";

import { FileIcon, PreferencesIcon, UsersIcon } from "../../../../assets/icons/interface/interface";
import FloatingFaces from "./FloatingFaces";

const features = [
	{
		icon: <FileIcon className="text-gray-900" />,
		title: "Create Job Posts in Minutes",
		from: "Spending hours manually crafting job listings with basic templates.",
		to: "Using AI-powered templates and smart suggestions that help you publish job posts in minutes—ensuring your listings attract the right talent instantly.",
	},
	{
		icon: <PreferencesIcon className="text-gray-900" />,
		title: "Instant AI Candidate Matching",
		from: "Manually sifting through hundreds of resumes and spending countless hours on screening.",
		to: "Letting advanced AI analyze resumes and instantly match you with the most qualified candidates, saving hours of manual work and presenting you with the best fits faster.",
	},
	{
		icon: <UsersIcon className="text-gray-900" />,
		title: "Unified Hiring Workflow",
		from: "A fragmented process with emails, spreadsheets, and scattered tools for scheduling interviews and collecting feedback.",
		to: "Managing your entire hiring process in one place—from scheduling interviews to collecting feedback and making offers—with a seamlessly integrated, centralized dashboard.",
	},
];

function PlatformFeatures() {
	return (
		<>
			<section className="platform-features">
				<div className="platform-features__container">
					<div className="platform-features__grid">
						{/* Left column - Text content */}
						<div className="platform-features__content">
							<h2 className="platform-features__title">Simplify Your Hiring Process</h2>
							<p className="platform-features__description">
								<span className="font-semibold">Our Promise:</span> Transform your outdated, manual hiring process into
								an efficient, data-driven system that saves you time and money.
							</p>
						</div>

						{/* Right column - Feature cards */}
						<div className="platform-features__cards">
							{features.map((feature, index) => (
								<div key={index} className="feature-card">
									<div className="feature-card__icon-wrapper">
										<div className="feature-card__icon">{feature.icon}</div>
									</div>
									<div className="feature-card__content">
										<h3 className="feature-card__title">{feature.title}</h3>
										<p className="feature-card__description !mb-2">
											<span className="font-semibold">From:</span> {feature.from}
										</p>
										<p className="feature-card__description">
											<span className="font-semibold">To:</span> {feature.to}
										</p>
										<div className="feature-card__learn-more">
											<div className="feature-card__learn-more-wrapper">
												<div className="feature-card__learn-more-button">
													<ChevronRightIcon className="feature-card__learn-more-icon" />
													<span className="feature-card__learn-more-text">Learn more</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</section>
			<FloatingFaces />
		</>
	);
}

export default PlatformFeatures;
