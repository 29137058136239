import React from "react";

import { DprozLogoIcon, Tick, XIcon } from "../../../../assets/icons/interface/interface";

const comparisonPoints = [
	{
		traditional: {
			title: "Manual CV Screening",
			description: "Hours wasted reading through hundreds of CVs.",
			icon: <XIcon className="w-5 h-5 text-gray-700" />,
		},
		smart: {
			title: "AI-Powered Matching",
			description: "Instantly match candidates based on skills and requirements",
			icon: <Tick className="w-5 h-5 text-gray-700" />,
		},
	},
	{
		traditional: {
			title: "Time-Consuming Shortlisting",
			description: "Days or even weeks to compile a qualified candidate shortlist",
			icon: <XIcon className="w-5 h-5 text-gray-700" />,
		},
		smart: {
			title: "Automated Shortlisting",
			description: "Generate a qualified shortlist in minutes with AI-driven ranking",
			icon: <Tick className="w-5 h-5 text-gray-700" />,
		},
	},
	{
		traditional: {
			title: "Communication Gaps",
			description: "Scattered emails and missed updates between team members",
			icon: <XIcon className="w-5 h-5 text-gray-700" />,
		},
		smart: {
			title: "Unified Communication",
			description: "Real-time collaboration and a centralized feedback system keep your team aligned",
			icon: <Tick className="w-5 h-5 text-gray-700" />,
		},
	},
	{
		traditional: {
			title: "Manual Interview Scheduling",
			description: "Endless back-and-forth emails to coordinate interview times.",
			icon: <XIcon className="w-5 h-5 text-gray-700" />,
		},
		smart: {
			title: "Smart Scheduling",
			description: "Automated scheduling integrated with your calendar to streamline interview coordination",
			icon: <Tick className="w-5 h-5 text-gray-700" />,
		},
	},
];

function ComparisonSection() {
	return (
		<section className="comparison-section">
			<div className="comparison-section__container">
				<div className="comparison-section__header">
					<h2 className="comparison-section__heading">Real Results, Real Transformation</h2>
					<p className="comparison-section__subheading">Traditional Hiring Processes Are Slow, Biased, and Outdated</p>
				</div>
				<div className="comparison-section__grid">
					{/* Manual Process */}
					<div className="comparison-card">
						<div className="comparison-card__content">
							<h3 className="comparison-card__title">Manual Process</h3>
							<p className="comparison-card__subtitle">Traditional hiring methods</p>
							<div className="comparison-card__list">
								{comparisonPoints.map((point, index) => (
									<div key={`manual-${index}`} className="comparison-card__list-item">
										<div className="comparison-card__list-item-content">
											<div className="comparison-card__list-item-icon">{point.traditional.icon}</div>
											<div className="comparison-card__list-item-text">
												<h4 className="comparison-card__list-item-title">{point.traditional.title}</h4>
												<p className="comparison-card__list-item-description">{point.traditional.description}</p>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>

					{/* Automated Process */}
					<div className="comparison-card">
						<div className="comparison-card__gradient" />
						<div className="comparison-card__shine" />
						<div className="comparison-card__content">
							<div className="flex justify-between items-start">
								<div>
									<h3 className="comparison-card__title">Automated Process</h3>
									<p className="comparison-card__subtitle">Smart hiring with Dproz</p>
								</div>
								<svg width="0" height="0">
									<defs>
										<linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
											<stop offset="0%" stopColor="#FF1A6C" />
											<stop offset="50%" stopColor="#FF758C" />
											<stop offset="100%" stopColor="#1E40AF" />
										</linearGradient>
									</defs>
								</svg>
								<DprozLogoIcon className="w-24 h-auto" style={{ fill: "url(#gradient)" }} />
							</div>
							<div className="comparison-card__list">
								{comparisonPoints.map((point, index) => (
									<div key={`smart-${index}`} className="comparison-card__list-item">
										<div className="comparison-card__list-item-content">
											<div className="comparison-card__list-item-icon">{point.smart.icon}</div>
											<div className="comparison-card__list-item-text">
												<h4 className="comparison-card__list-item-title">{point.smart.title}</h4>
												<p className="comparison-card__list-item-description">{point.smart.description}</p>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default ComparisonSection;
