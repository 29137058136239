import React from "react";
import { Link } from "react-router-dom";

function Categories({ categories }) {
	return (
		<div className="grid grid-cols-2 md:grid-cols-4 gap-4">
			{categories.slice(0, 8).map((category, index) => (
				<Link
					className="flex items-center p-4 h-full cursor-pointer bg-white rounded-lg shadow-sm transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-md border border-gray-100"
					key={index}
					to={"/jobs?category=" + category._id}
				>
					<div className="bg-primary-100 flex items-center justify-center p-3 rounded-full mr-4 transition-all duration-300 ease-in-out group-hover:bg-primary-200">
						<img
							alt={category.category}
							loading="lazy"
							width="32"
							height="32"
							decoding="async"
							data-nimg="1"
							src={`/images/landing/${category._id}.svg`}
						/>
					</div>
					<div className="text-left">
						<h3 className="text-base font-medium text-gray-800 font-inter transition-colors duration-300 ease-in-out group-hover:text-primary-600">
							{category._id}
						</h3>
						<span className="text-sm text-gray-500 transition-colors duration-300 ease-in-out group-hover:text-primary-600">
							{category.count} jobs
						</span>
					</div>
				</Link>
			))}
		</div>
	);
}

export default Categories;
