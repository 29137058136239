import "./for-employers.css";

import React from "react";

import useIsLoggedIn from "../../../hooks/use-IsLoggedIn";
import Header from "../Header";
import ComparisonSection from "./components/ComparisonSection";
import CTASection from "./components/CTASection";
import FeaturesShowcase from "./components/FeaturesShowcase";
import HiringHero from "./components/HiringHero";
import PlatformFeatures from "./components/PlatformFeatures";
import ValueProposition from "./components/ValueProposition";
import EmployerFooter from "./EmployerFooter";

function EmployersLandingPage() {
	const isLoggedIn = useIsLoggedIn();

	return (
		<div className="min-h-screen bg-white">
			<Header isLoggedIn={isLoggedIn} />

			<div className="min-h-screen bg-white">
				<HiringHero />

				<PlatformFeatures />

				<ComparisonSection />

				<ValueProposition />

				<FeaturesShowcase />

				<CTASection />

				<EmployerFooter variant="employer" />
			</div>
		</div>
	);
}

export default EmployersLandingPage;
