import React from "react";
import { useNavigate } from "react-router-dom";

function SimilarCompanies({ company }) {
	const { companyDetails } = company;

	const navigate = useNavigate();

	return (
		<div
			className="bg-white rounded-lg p-6 flex flex-col h-full"
			style={{ boxShadow: "0 0 0 1px rgba(0,0,0,.05), 0 2px 2px rgba(0,0,0,.04)" }}
		>
			<div className="flex items-center mb-3">
				<div className="w-16 h-16 rounded-lg overflow-hidden flex-shrink-0 border border-gray-100">
					<img
						alt="AfriTech Solutions logo"
						loading="lazy"
						width="64"
						height="64"
						decoding="async"
						data-nimg="1"
						className="w-full h-full object-cover"
						src={companyDetails.logo_url}
						onError={(e) => (e.target.src = "/images/co-placeholder.png")}
					/>
				</div>
				<h3 className="ml-4 text-xl font-semibold text-gray-900">{companyDetails.company_name}</h3>
			</div>
			<p className="text-gray-700 mb-3">{companyDetails.company_industry}</p>
			<div className="flex flex-wrap gap-2 mb-3">
				<span className="inline-flex items-center h-6 px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-700">
					{companyDetails.company_industry}
				</span>
				<span className="inline-flex items-center h-6 px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-700">
					{companyDetails.company_size_approx} employees
				</span>
				<span className="inline-flex items-center h-6 px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-700">
					{companyDetails.headquarters}
				</span>
			</div>
			<div className="mt-auto pt-4">
				<div className="flex space-x-2">
					<span
						className="flex-1 bg-gray-100 text-gray-700 px-4 h-9 rounded-lg hover:bg-gray-200 transition-colors font-inter text-center text-sm font-medium flex items-center justify-center"
						onClick={() =>
							navigate(`/companies/${company.company}`, {
								state: { company },
							})
						}
					>
						View Profile
					</span>
					<button className="inline-flex items-center justify-center rounded-md focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background border hover:text-accent-foreground py-2 px-4 flex-1 border-gray-300 text-gray-700 hover:bg-gray-100 transition-colors font-inter text-sm font-medium h-9">
						Follow
					</button>
				</div>
			</div>
		</div>
	);
}

export default SimilarCompanies;
