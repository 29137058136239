import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";

import { Button } from "../../../JobsPage/components/Button";

function CTASection() {
	return (
		<section className="cta-section">
			<div className="cta-section__container">
				<motion.div
					className="cta-section__content pt-16"
					initial={{ opacity: 0, y: 20 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.6, ease: "easeOut" }}
				>
					<motion.h2
						className="cta-section__title"
						initial={{ opacity: 0, y: 20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.6, ease: "easeOut", delay: 0.1 }}
					>
						Ready to Transform Your Hiring Process?
					</motion.h2>
					<motion.p
						className="cta-section__description"
						initial={{ opacity: 0, y: 20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.6, ease: "easeOut", delay: 0.2 }}
					>
						Join the leading companies in Tanzania who are already hiring smarter with Dproz. Experience the measurable
						difference of a streamlined, AI-powered recruitment process.
					</motion.p>
					<motion.div
						className="cta-section__buttons"
						initial={{ opacity: 0, y: 20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.6, ease: "easeOut", delay: 0.3 }}
					>
						<Link to="https://hiring.dproz.com/signup">
							<Button className="h-14 px-12 text-base font-medium bg-[#2563eb] hover:bg-[#1d4ed8] text-white rounded-full">
								Get Started
							</Button>
						</Link>
					</motion.div>
				</motion.div>
			</div>
		</section>
	);
}

export default CTASection;
