import React from "react";

import { cn } from "../../../../handlers/cn.utils";
import mapping from "../../../../seed/metadata/filemapping";

function LocationSearchDropdown({ addressLocation, setAddressLocation }) {
	const addresses = mapping["districtsRegions"][0].districtRegions;
	const filteredAddresses = addresses.filter((option) => option.toLowerCase().includes(addressLocation.toLowerCase()));
	const [showAddresses, setShowAddresses] = React.useState(false);

	return (
		<div className="flex-grow w-full">
			<div className="relative flex-grow">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					strokeWidth="1.5"
					stroke="currentColor"
					className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-300 w-5 h-5"
				>
					<path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
					/>
				</svg>
				<input
					value={addressLocation}
					type="text"
					className="flex w-full border border-input px-3 py-2 ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 flex-grow h-12 text-base !rounded-md !pl-12 placeholder-gray-600 bg-white focus:ring-2 focus:ring-primary-600 focus:border-transparent font-semibold placeholder:font-semibold"
					placeholder="Location"
					onChange={(e) => setAddressLocation(e.target.value)}
					onFocus={() => {
						setShowAddresses(true);
					}}
					onBlur={() => {
						setTimeout(() => {
							setShowAddresses(false);
						}, 500);
					}}
				/>
				{showAddresses && (
					<ul className="bg-[white] max-h-48 overflow-y-auto rounded-lg border shadow-sm w-full z-50 absolute">
						{filteredAddresses.length > 0 ? (
							filteredAddresses.map((address, index) => (
								<li
									key={index}
									className={cn("cursor-pointer p-2 text-left hover:bg-gray-50", {
										"bg-gray-100": addressLocation === address,
									})}
									onClick={() => {
										setAddressLocation(address);
									}}
								>
									{address}
								</li>
							))
						) : (
							<li className="p-2 text-gray-500">No results found</li>
						)}
					</ul>
				)}
			</div>
		</div>
	);
}

export default LocationSearchDropdown;
