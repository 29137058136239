import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";

import { cn } from "../../handlers/cn.utils";
import useIsLoggedIn from "../../hooks/use-IsLoggedIn";
import {
	useActivelyHiringCompanies,
	useCompany,
	useCompanyJobs,
	useFeaturedEmployers,
} from "../../services/create-resume/queries";
import Header from "../Profile/components/Header";
import CompanyJobs from "./components/CompanyJobs";
import Overview from "./components/Overview";
import SimilarCompanies from "./components/SimilarCompanies";

function Company() {
	const isLoggedIn = useIsLoggedIn();
	const { id } = useParams();

	const [employers, setEmployers] = React.useState([]);

	const { data: response, isLoading, refetch } = useFeaturedEmployers();
	const { data, isLoading: loadingCompany, isError } = useCompany(id);
	const { data: jobs, isLoading: isLoadingJobs } = useCompanyJobs(id);
	const { data: companies, isLoading: isLoadingActivelyHiringCompanies } = useActivelyHiringCompanies();

	const [companyData, setCompanyData] = React.useState();
	const [companyJobs, setCompanyJobs] = React.useState([]);
	const [activelyHiringCompanies, setActivelyHiringCompanies] = React.useState([]);

	const isCompanyActivelyHiring = (companyId) => {
		return activelyHiringCompanies.includes(companyId);
	};

	useEffect(() => {
		if (!isLoadingActivelyHiringCompanies && companies) {
			setActivelyHiringCompanies(companies.data);
		}
	}, [data]);

	useEffect(() => {
		if (!loadingCompany && data) {
			setCompanyData(data.data);
		}
	}, [data]);

	useEffect(() => {
		if (!isLoadingJobs && jobs) {
			setCompanyJobs(jobs.data);
			if (!(jobs.data.length > 0)) {
				setActiveTab("about");
			}
		}
	}, [jobs]);

	useEffect(() => {
		if (!isLoading && response) {
			setEmployers(response.data);
		}
	}, [isLoading, response]);

	const [activeTab, setActiveTab] = React.useState("jobs");

	useEffect(() => {
		refetch();
	}, [id]);

	return (
		<main>
			<Header isLoggedIn={isLoggedIn} />

			<div className="relative overflow-hidden">
				<div className="relative z-10 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
					<nav className="flex" aria-label="Breadcrumb">
						<ol className="inline-flex items-center">
							<li className="inline-flex items-center">
								<Link
									className="inline-flex items-center text-base font-medium text-gray-500 hover:text-gray-700"
									to="/"
								>
									Home
								</Link>
							</li>
							<li className="inline-flex items-center">
								<span className="mx-2 text-gray-400">/</span>
								<Link
									className="inline-flex items-center text-base font-medium text-gray-500 hover:text-gray-700"
									to="/companies"
								>
									Companies
								</Link>
							</li>
							<li className="inline-flex items-center">
								<span className="mx-2 text-gray-400">/</span>
								<Link
									className="inline-flex items-center text-base font-medium text-gray-700 hover:text-gray-900"
									to={`/companies/${companyData?._id}`}
								>
									{companyData?.company_name}
								</Link>
							</li>
						</ol>
					</nav>
				</div>
			</div>

			{loadingCompany && !isError ? (
				<section className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
					<div className="py-8 animate-pulse">
						<div className="flex items-center justify-between mb-4">
							<div className="flex items-center">
								<div className="w-24 h-24 rounded-lg overflow-hidden flex-shrink-0 border border-gray-100 bg-gray-200" />
								<div className="ml-6">
									<div className="h-6 bg-gray-200 rounded w-1/2 my-1" />
									<div className="h-4 bg-gray-200 rounded w-3/4 my-1" />
								</div>
							</div>
							<div className="btn h-10 bg-gray-200 rounded w-20" />
						</div>
						<div className="flex flex-wrap gap-2 text-sm text-gray-600 font-inter mb-4">
							<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100/80 text-gray-800">
								<div className="w-20 h-5 bg-gray-200 rounded" />
							</span>
							<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100/80 text-gray-800">
								<div className="w-20 h-5 bg-gray-200 rounded" />
							</span>
							<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-success-light text-success-default">
								<div className="w-20 h-5 bg-gray-200 rounded" />
							</span>
						</div>
						<div className="flex flex-wrap gap-4 text-md text-gray-600 font-inter">
							<div className="flex items-center">
								<span className="font-semibold mr-1">
									<div className="w-20 h-5 bg-gray-200 rounded" />
								</span>
								<a href="#" target="_blank" rel="noopener noreferrer" className="text-primary-700 font-medium">
									<div className="w-20 h-5 bg-gray-200 rounded" />
								</a>
							</div>
						</div>
					</div>
				</section>
			) : (
				<section className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
					<Helmet>
						<title>{companyData?.company_name + " | Dproz" || "Dproz"} | DProz</title>
						<meta name="description" content={companyData?.about_us} />
						<meta property="keywords" content={companyData?.company_name} />
						<meta property="og:image" content={companyData?.logo_url} />
						<meta property="og:image:secure_url" content={companyData?.logo_url} />
						<meta property="og:image:alt" content={companyData?.company_name + " logo"} />
						<meta property="og:image:type" content="image/png" />
						<meta property="og:image:width" content="96" />
						<meta property="og:image:height" content="96" />
						<meta property="og:description" content={companyData?.about_us} />
						<meta property="og:title" content={companyData?.company_name} />
						<meta property="og:type" content="website" />
					</Helmet>
					<div className="py-8">
						<div className="flex items-center justify-between mb-4">
							<div className="flex items-center">
								<div className="w-24 h-24 rounded-lg overflow-hidden flex-shrink-0 border border-gray-100">
									<img
										alt={companyData?.company_name + " logo"}
										loading="lazy"
										width="96"
										height="96"
										decoding="async"
										data-nimg="1"
										className="w-full h-full object-cover"
										src={companyData?.logo_url}
										onError={(e) => (e.target.src = "/images/co-placeholder.png")}
									/>
								</div>
								<div className="ml-6">
									<h2 className="!text-3xl font-bold text-gray-900 font-plus-jakarta">{companyData?.company_name}</h2>
									<p className="text-gray-700 mt-1 font-inter">{companyData?.specialties}</p>
								</div>
							</div>
							<button className="inline-flex items-center justify-center rounded-md text-sm font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background border hover:text-accent-foreground h-10 py-2 px-4 border-gray-300 text-gray-700 hover:bg-gray-100 transition-colors font-inter">
								Follow
							</button>
						</div>
						<div className="flex flex-wrap gap-2 text-sm text-gray-600 font-inter mb-4">
							<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100/80 text-gray-800">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth="1.5"
									stroke="currentColor"
									className="w-3 h-3 mr-1 text-gray-600"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
									/>
								</svg>
								{companyData?.company_industry}
							</span>
							<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100/80 text-gray-800">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth="1.5"
									stroke="currentColor"
									className="w-3 h-3 mr-1 text-gray-600"
								>
									<path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
									/>
								</svg>
								{companyData?.headquarters}
							</span>
							{isCompanyActivelyHiring(companyData?._id) && (
								<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-success-light text-success-default">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth="1.5"
										stroke="currentColor"
										className="w-3 h-3 mr-1 text-green-600"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
										/>
									</svg>
									Actively Hiring
								</span>
							)}
						</div>
						<div className="flex flex-wrap gap-4 text-sm text-gray-600 font-inter">
							<div className="flex items-center">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth="1.5"
									stroke="currentColor"
									className="w-5 h-5 mr-2 text-gray-600"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="m6.115 5.19.319 1.913A6 6 0 0 0 8.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 0 0 2.288-4.042 1.087 1.087 0 0 0-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 0 1-.98-.314l-.295-.295a1.125 1.125 0 0 1 0-1.591l.13-.132a1.125 1.125 0 0 1 1.3-.21l.603.302a.809.809 0 0 0 1.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 0 0 1.528-1.732l.146-.292M6.115 5.19A9 9 0 1 0 17.18 4.64M6.115 5.19A8.965 8.965 0 0 1 12 3c1.929 0 3.716.607 5.18 1.64"
									/>
								</svg>
								<span className="font-semibold mr-1">Website:</span>
								<a
									href={companyData?.company_website}
									target="_blank"
									rel="noopener noreferrer"
									className="text-primary-700 font-medium hover:underline"
								>
									{companyData?.company_website}
								</a>
							</div>
						</div>
					</div>
					<div className="flex flex-col md:flex-row gap-8">
						<div className="flex-1">
							<div className="">
								<div className="border-b border-gray-200">
									<nav className="-mb-px flex space-x-8" aria-label="Tabs">
										<span
											className={cn(
												"cursor-pointer py-4 px-1 font-semibold text-md text-gray-500 hover:text-gray-700 hover:border-gray-500 border-b-2 border-[transparent]",
												{
													"border-gray-900 text-gray-900": activeTab === "jobs",
												},
											)}
											onClick={() => setActiveTab("jobs")}
										>
											Jobs
										</span>
										<span
											className={cn(
												"cursor-pointer py-4 px-1 font-semibold text-md text-gray-500 hover:text-gray-700 hover:border-gray-500 border-b-2 border-[transparent]",
												{
													"border-gray-900 text-gray-900": activeTab === "about",
												},
											)}
											onClick={() => setActiveTab("about")}
										>
											About
										</span>
									</nav>
								</div>
								{activeTab === "jobs" ? (
									<>
										{companyJobs ? (
											<CompanyJobs jobs={companyJobs} logo={companyData?.logo_url} />
										) : (
											<div className="pt-6">
												<h2 className="text-2xl font-semibold mb-4 font-plus-jakarta">No jobs available</h2>
											</div>
										)}
									</>
								) : (
									<div className="mt-4">
										<div className="pt-6">
											<h2 className="text-2xl font-semibold mb-4 font-plus-jakarta">
												About {companyData?.company_name}
											</h2>
											<p className="text-gray-700 mb-4">{companyData?.about_us}</p>
											{false && (
												<>
													<h3 className="text-lg font-semibold mb-2">Mission</h3>
													<p className="text-gray-700 mb-4">
														To empower businesses and individuals through cutting-edge technology solutions.
													</p>
												</>
											)}
											{false && (
												<>
													<h3 className="text-lg font-semibold mb-2">Values</h3>
													<ul className="list-disc list-inside mb-4">
														<li className="text-gray-700">Innovation</li>
														<li className="text-gray-700">Integrity</li>
														<li className="text-gray-700">Collaboration</li>
														<li className="text-gray-700">Excellence</li>
													</ul>
												</>
											)}
										</div>
									</div>
								)}
							</div>
						</div>
						{companyData && <Overview companyData={companyData} />}
					</div>
				</section>
			)}

			{!loadingCompany && (
				<div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
					<h2 className="text-2xl font-semibold mb-6">Similar Companies</h2>
					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
						{isLoading ? (
							<>
								{[...new Array(3)].map((_, index) => (
									<div className="bg-gray-50 rounded-lg p-6 flex flex-col h-80 animate-pulse" key={index}>
										<div className="flex items-center mb-3">
											<div className="w-16 h-16 rounded-lg overflow-hidden flex-shrink-0 border border-gray-100 bg-gray-200" />
											<div className="ml-4 h-6 bg-gray-200 rounded w-1/2" />
										</div>
										<div className="h-4 bg-gray-200 rounded w-full my-1" />
										<div className="flex flex-wrap gap-2 mb-3">
											<span className="inline-flex items-center h-6 aspect-square px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-gray-700" />
											<span className="inline-flex items-center h-6 aspect-square px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-gray-700" />
											<span className="inline-flex items-center h-6 aspect-square px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-gray-700" />
										</div>
										<div className="mt-auto pt-4">
											<div className="flex space-x-2">
												<span className="btn bg-gray-200 text-black w-full" />
												<button className="btn text-black border-gray-200 w-full h-10" />
											</div>
										</div>
									</div>
								))}
							</>
						) : (
							<>
								{employers.slice(0, 3).map((company, index) => (
									<SimilarCompanies key={index} company={company} />
								))}
							</>
						)}
					</div>
				</div>
			)}
		</main>
	);
}

export default Company;
