import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import useIsLoggedIn from "../../hooks/use-IsLoggedIn";
import { useActivelyHiringCompanies, useCompanies } from "../../services/create-resume/queries";
import Header from "../Profile/components/Header";
import Dropdown from "./components/Dropdown";

const PAGE_SIZE = 15;

function Companies() {
	const isLoggedIn = useIsLoggedIn();
	const navigate = useNavigate();

	const [companies, setCompanies] = useState([]);
	const [page, setPage] = useState(1);

	const [industries, setIndustries] = useState([]);
	const [locations, setLocations] = useState([]);
	const hiringStatus = ["All Status", "Actively Hiring"];
	const { data: hiringCompanies, isLoading: isLoadingActivelyHiringCompanies } = useActivelyHiringCompanies();

	const { data: response, isLoading } = useCompanies();

	useEffect(() => {
		if (response && !isLoading) {
			const { data } = response;
			setCompanies(data);

			const allIndustries = [
				"All Industries",
				...data.map((item) => item.company_industry).filter((item) => item !== "N/A"),
			];

			const allLocations = [
				"All Locations",
				...data.map((item) => item.headquarters.split(",")[0]).filter((item) => item !== "N/A"),
			];

			setIndustries([...new Set(allIndustries)]);
			setLocations([...new Set(allLocations)]);
		}
	}, [response, isLoading]);

	const [filteredJobs, setFilteredJobs] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");

	const [selectedHiringStatus, setSelectedHiringStatus] = useState("All Status");
	// eslint-disable-next-line unused-imports/no-unused-vars
	const [selectedIndustry, setSelectedIndustry] = useState("All Industries");
	// eslint-disable-next-line unused-imports/no-unused-vars
	const [selectedLocation, setSelectedLocation] = useState("All Locations");
	const [activelyHiringCompanies, setActivelyHiringCompanies] = React.useState([]);

	const isCompanyActivelyHiring = (companyId) => {
		return activelyHiringCompanies.includes(companyId);
	};

	useEffect(() => {
		if (!isLoadingActivelyHiringCompanies && hiringCompanies) {
			setActivelyHiringCompanies(hiringCompanies.data);
		}
	}, [companies]);

	useEffect(() => {
		if (!response && isLoading) return;

		let companyData = companies;

		if (searchTerm) {
			companyData = companyData.filter((item) => item.company_name.toLowerCase().includes(searchTerm.toLowerCase()));
		}

		if (selectedIndustry !== "All Industries") {
			companyData = companyData.filter((item) => item.company_industry === selectedIndustry);
		}

		if (selectedLocation !== "All Locations") {
			companyData = companyData.filter((item) => item.headquarters === selectedLocation);
		}

		if (selectedHiringStatus !== "All Status") {
			companyData = companyData.filter((company) => isCompanyActivelyHiring(company._id));
		}

		setFilteredJobs(companyData.filter((company) => company.company_industry !== "N/A"));
		window.scrollTo(0, 0);
	}, [companies, page, searchTerm, selectedIndustry, selectedHiringStatus, selectedLocation, isLoading]);

	const numberOfPages = useMemo(() => {
		setPage(1);
		return Math.ceil(filteredJobs.length / PAGE_SIZE);
	}, [JSON.stringify(filteredJobs)]);

	return (
		<main>
			<Header isLoggedIn={isLoggedIn} />
			<div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-8 mt-8 flex-grow">
				<div className="bg-blue-50/50 rounded-lg p-6 mb-8 border !border-blue-100">
					<div className="flex justify-between items-center gap-8">
						<div className="flex-[2]">
							<div className="flex items-center space-x-2 mb-1">
								<span className="text-sm font-medium text-primary-600">Featured Companies</span>
							</div>
							<h2 className="text-xl font-semibold text-gray-900 mb-2">Get in front of top talent</h2>
							<p className="text-gray-800 text-sm mb-4">
								Feature your company profile and job postings to attract the best candidates. Get priority placement in
								search results and showcase your employer brand.
							</p>
							<button className="text-gray-700 text-sm h-9 bg-white hover:bg-gray-50 px-4 rounded border !border-gray-300 font-medium">
								Learn more
							</button>
						</div>
						<div className="flex-1">
							<div className="w-full aspect-[2/1] relative">
								<img
									alt="Featured company benefits"
									loading="lazy"
									decoding="async"
									data-nimg="fill"
									className="object-contain absolute h-full w-full left-0 top-0 bottom-0 right-0"
									sizes="100vw"
									src="/images/featured-company.png"
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="mb-8 flex flex-col sm:flex-row gap-4">
					<div className="relative flex-grow">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth="1.5"
							stroke="currentColor"
							className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-500"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
							/>
						</svg>
						<input
							className="flex w-full !rounded border-input px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 !pl-9 h-9 bg-gray-50 border-0 text-gray-900 placeholder:text-gray-600"
							placeholder="Search companies..."
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
						/>
					</div>
					<div className="flex gap-4">
						<Dropdown
							label="All"
							data={hiringStatus}
							onSelect={setSelectedHiringStatus}
							selectedOption={selectedHiringStatus}
						/>
						<Dropdown
							label="All Industries"
							data={industries}
							onSelect={setSelectedIndustry}
							selectedOption={selectedIndustry}
						/>
						<Dropdown
							label="All Locations"
							data={locations}
							onSelect={setSelectedLocation}
							selectedOption={selectedLocation}
						/>
					</div>
				</div>

				{isLoading ? (
					<>
						{[...new Array(3)].map((_, index) => (
							<div className="bg-gray-50 rounded-lg p-6 flex flex-col h-80 animate-pulse" key={index}>
								<div className="flex items-center mb-3">
									<div className="w-16 h-16 rounded-lg overflow-hidden flex-shrink-0 border border-gray-100 bg-gray-200" />
									<div className="ml-4 h-6 bg-gray-200 rounded w-1/2" />
								</div>
								<div className="h-4 bg-gray-200 rounded w-full my-1" />
								<div className="flex flex-wrap gap-2 mb-3">
									<span className="inline-flex items-center h-6 aspect-square px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-gray-700" />
									<span className="inline-flex items-center h-6 aspect-square px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-gray-700" />
									<span className="inline-flex items-center h-6 aspect-square px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-gray-700" />
								</div>
								<div className="mt-auto pt-4">
									<div className="flex space-x-2">
										<span className="btn bg-gray-200 text-black w-full" />
										<button className="btn text-black border-gray-200 w-full h-10" />
									</div>
								</div>
							</div>
						))}
					</>
				) : (
					<>
						{filteredJobs.length === 0 ? (
							<div className="flex justify-center align-items-center">
								<div>No Companies Found!</div>
							</div>
						) : (
							<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
								{filteredJobs.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE).map((company) => (
									<div
										className="bg-white rounded-lg p-6 flex flex-col h-full"
										style={{ boxShadow: "0 0 0 1px rgba(0,0,0,.05), 0 2px 2px rgba(0,0,0,.04)" }}
										key={company._id}
									>
										<div className="flex items-start mb-3">
											<div className="w-16 h-16 rounded-lg overflow-hidden flex-shrink-0 border border-gray-100 flex items-center justify-center">
												<img
													alt={company.company_name + " logo"}
													loading="lazy"
													width="64"
													height="64"
													decoding="async"
													data-nimg="1"
													className="w-full h-full object-cover"
													src={company.logo_url}
													onError={(e) => (e.target.src = "/images/co-placeholder.png")}
												/>
											</div>
											<div className="ml-4">
												<h3 className="text-base font-semibold font-inter text-gray-900">{company.company_name}</h3>
												<div className="flex items-center mt-1 text-gray-600">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														strokeWidth="1.5"
														stroke="currentColor"
														className="w-4 h-4 mr-1"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
														/>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
														/>
													</svg>
													<span className="text-[14px]">{company.headquarters}</span>
												</div>
											</div>
										</div>
										<p className="text-[14px] text-gray-600 mb-3 font-inter leading-relaxed">
											{company.specialties ? company.specialties : company.company_industry}
										</p>
										<div className="flex flex-wrap gap-2 mb-3">
											<span className="inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-700 border border-gray-200 font-inter">
												{company.company_industry}
											</span>
											<span className="inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-700 border border-gray-200 font-inter">
												{company.company_size_approx} employees
											</span>
											{isCompanyActivelyHiring(company?._id) && (
												<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-success-light text-success-default">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														strokeWidth="1.5"
														stroke="currentColor"
														className="w-3 h-3 mr-1 text-green-600"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
														/>
													</svg>
													Actively Hiring
												</span>
											)}
										</div>
										<div className="mt-auto pt-4 flex space-x-2">
											<a
												className="w-1/2 inline-flex items-center justify-center bg-primary-700 text-white h-9 !rounded hover:bg-primary-800 transition-colors duration-300 text-sm font-medium font-inter group relative overflow-hidden cursor-pointer"
												onClick={() =>
													navigate(`/companies/${company._id}`, {
														state: {
															company: {
																companyDetails: company,
															},
														},
													})
												}
											>
												<span className="mr-1 group-hover:mr-3 transition-all duration-300">View Profile</span>
												<span className="absolute right-4 opacity-0 transition-all duration-300 transform translate-x-2 group-hover:opacity-100 group-hover:translate-x-0">
													›
												</span>
											</a>
											<button className="w-1/2 bg-white border border-gray-300 text-gray-700 px-4 h-9 !rounded hover:bg-gray-50 transition-colors font-inter text-center flex items-center justify-center text-sm font-medium">
												Follow
											</button>
										</div>
									</div>
								))}
							</div>
						)}
						<div className="flex justify-center mt-8 space-x-2">
							<button
								className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background border border-input hover:bg-accent hover:text-accent-foreground h-10 py-2 px-4"
								disabled={page === 1}
								onClick={() => setPage(page - 1)}
							>
								Previous
							</button>
							<button
								className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background border border-input hover:bg-accent hover:text-accent-foreground h-10 py-2 px-4"
								disabled={page === numberOfPages || numberOfPages === 0}
								onClick={() => setPage(page + 1)}
							>
								Next
							</button>
						</div>
					</>
				)}
			</div>
		</main>
	);
}

export default Companies;
