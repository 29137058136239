import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

import useIsLoggedIn from "../../hooks/use-IsLoggedIn";
import { useJobCategories, useJobs, useSavedJobs } from "../../services/create-resume/queries";
import Header from "../Profile/components/Header";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../Profile/components/Tabs";
import Footer from "../StaticPages/Footer";
import Loader from "../StaticPages/HomePage/components/Loader";
import { Button } from "./components/Button";
import Categories from "./components/Categories";
import Jobs from "./components/Jobs";
import Locations from "./components/Locations";
import SavedJobs from "./components/SavedJobs";
import Search from "./components/Search";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./components/Select";

let persistJobsData = [];

function JobsPage() {
	const location = useLocation();

	const { searchedJobTitle, searchedAddress, jobSearchSource, jobCategory } = location.state || {};

	const [savedJobs, setSavedJobs] = useState([]);
	const {
		data: savedJobsResponse,
		isLoading: isLoadingSavedJobs,
		isRefetching: isRefetchingSavedJobs,
	} = useSavedJobs();

	const [categories, setCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(jobCategory || "all");

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search);
		const category = urlParams.get("category");
		if (category) {
			setSelectedCategory(category);
		}
	}, [location.search]);

	useEffect(() => {
		if (!isLoadingSavedJobs && savedJobsResponse) {
			setSavedJobs(savedJobsResponse.data);
		}
	}, [savedJobsResponse]);

	const [jobs, setJobs] = useState([]);

	const [noNextPage, setNoNextPage] = useState(true);
	const [pageNumber, setPageNumber] = useState(1);
	const [pagination, setPagination] = useState([]);
	const [addresses, setAddresses] = useState([]);
	const [jobTypes, setJobTypes] = useState([]);
	const isUserLoggedIn = useIsLoggedIn();
	const [experienceLevels, setExperienceLevels] = useState([]);
	const [searchedKeyWord, setSearchKeyword] = useState(searchedJobTitle || "");
	const [addressLocation, setAddressLocation] = useState(searchedAddress || "");
	const [selectedJobTypes, setSelectedJobTypes] = useState([]);
	const [selectedExperienceLevels, setSelectedExperienceLevels] = useState([]);
	const [selectedSortingType, setSelectedSortingType] = useState("upcoming");

	const handleNext = () => {
		fetchJobsOnSearch({ page: pageNumber + 1 });
	};

	const handlePrevious = () => {
		if (pageNumber > 1) {
			fetchJobsOnSearch({ page: pageNumber - 1 });
		}
	};

	const [searchParams, setSearchParams] = useState({
		title: "",
		country: "Tanzania",
		region: "",
		city: "",
		jobExpertise: [],
		pageNumber: 1,
	});

	const fetchJobsOnSearch = ({ page: page, categoryName: categoryName, location: location, keyWord: keyWord }) => {
		const address = location?.split(",") || addressLocation.split(",");
		const searchQueryParams = {
			title: keyWord !== undefined ? keyWord : searchedKeyWord !== "" ? searchedKeyWord : "",
			country: "Tanzania",
			region: address?.[1] || "",
			city: address?.[0] || "",
			jobExpertise: [categoryName || selectedCategory].filter((category) => category.toLowerCase() !== "all"),
			pageNumber: page,
		};
		setSearchParams(searchQueryParams);
	};

	const { data, isLoading, error } = useJobs({ params: searchParams });
	useEffect(() => {
		if (!isLoading && data) {
			successCallBack(data);
		}
	}, [data, isLoading]);

	useEffect(() => {
		if (!isLoading && error) {
			toast.error("Something went wrong");
		}
	}, [error, isLoading]);

	useEffect(() => {
		fetchJobsOnSearch({ page: 1, categoryName: selectedCategory });
	}, [selectedCategory]);

	const successCallBack = (response) => {
		let jobLocations = [];
		let locationCountMap = new Map();

		let employmentTypes = [];
		let employmentTypeCountMap = new Map();

		let jobExperienceLevels = [];
		let jobExperienceLevelCountMap = new Map();

		response?.data?.[0]?.paginatedJobs?.forEach((item) => {
			item.jobs.forEach((job) => {
				const location = `${job.jobLocation.city}, ${job.jobLocation.country}`;
				locationCountMap.set(location, (locationCountMap.get(location) || 0) + 1);
				employmentTypeCountMap.set(job.employmentType, (employmentTypeCountMap.get(job.employmentType) || 0) + 1);
				jobExperienceLevelCountMap.set(
					job.experienceQualification.minimumLevelOfExperience,
					(jobExperienceLevelCountMap.get(job.experienceQualification.minimumLevelOfExperience) || 0) + 1,
				);
			});
		});

		jobLocations = Array.from(locationCountMap, ([location, count]) => ({ location, count }));
		employmentTypes = Array.from(employmentTypeCountMap, ([name, count]) => ({ name, count }));
		jobExperienceLevels = Array.from(jobExperienceLevelCountMap, ([name, count]) => ({ name, count }));

		const newJobs = response?.data?.[0]?.paginatedJobs || [];
		sortJobs(newJobs, selectedSortingType);
		persistJobsData = newJobs;

		setAddresses(jobLocations);
		setJobTypes(employmentTypes);
		setExperienceLevels(jobExperienceLevels);

		//calculate next pagination
		calculatePagination(response.data[0]);
	};

	const calculatePagination = (response) => {
		setPageNumber(response.pageNumber);
		let _page = [...pagination];

		if (pagination.length === 0) {
			_page.push({ currentPage: response.pageNumber, totalJobs: response.totalJobs });
			setPagination(_page);
		}

		const page = _page.find((page) => page.currentPage === response.pageNumber);

		const _totalJobs = page.totalJobs;
		const pagedJobs = response.paginatedJobs.flatMap((company) => company.jobs).length;

		if (response.pageSize - response.totalJobsInPage > 0) {
			setNoNextPage(true);
			return;
		}

		if (_totalJobs - pagedJobs > 0) {
			if (pagination.find((page) => page.currentPage === response.pageNumber) === undefined) {
				_page.push({ currentPage: response.pageNumber + 1, totalJobs: _totalJobs - pagedJobs });
				setPagination(_page);
			}

			setNoNextPage(false);

			return;
		}

		setNoNextPage(true);
	};

	const { data: response, isLoading: loadingCategories } = useJobCategories();

	function filterJobs(jobs, filters) {
		return jobs.filter((job) => {
			return Object.keys(filters).every((key) => {
				// Skip if filter is not provided
				if (filters[key] === undefined || filters[key] === null) return true;

				// Handle nested fields
				if (key === "jobLocation") {
					return job.jobLocation.city.toLowerCase().includes(filters[key].toLowerCase());
				}

				if (key === "jobCategory") {
					return job.jobExpertise.toLowerCase().includes(filters[key].toLowerCase());
				}

				if (key === "jobTitle") {
					return job.jobTitle.toLowerCase().includes(filters[key].toLowerCase());
				}

				if (key === "employmentTypes") {
					return filters[key].some((type) => job.employmentType.toLowerCase().includes(type.toLowerCase()));
				}

				if (key === "experienceLevels") {
					return filters[key].some((level) =>
						job.experienceQualification.minimumLevelOfExperience.toLowerCase().includes(level.toLowerCase()),
					);
				}

				return false; // If the key doesn't match, return false
			});
		});
	}

	function sortJobs(jobsToBeSorted, sortingType) {
		const _jobs = [...jobsToBeSorted];

		// Function to get the closest closeAt date for a job
		function getCloseAtDate(job) {
			return new Date(job.closeAt);
		}

		// Function to get the lastUpdatedAt date for a job
		function getLastUpdatedAtDate(job) {
			return new Date(job.lastUpdatedAt);
		}

		// Sort companies based on their jobs' closeAt or lastUpdatedAt date
		_jobs.sort((a, b) => {
			// For "upcoming", we now just sort by the closest closeAt date in ascending order (oldest first, newest last)
			if (sortingType === "upcoming") {
				// Sort jobs within each company by closeAt date (ascending order)
				a.jobs.sort((job1, job2) => getCloseAtDate(job1) - getCloseAtDate(job2));
				b.jobs.sort((job1, job2) => getCloseAtDate(job1) - getCloseAtDate(job2));

				// Compare companies based on the earliest closeAt date from their sorted job list
				return getCloseAtDate(a.jobs[0]) - getCloseAtDate(b.jobs[0]);
			}

			// For "latest", we want the job with the most recent lastUpdatedAt
			if (sortingType === "latest") {
				// Find the job with the most recent lastUpdatedAt for each company
				const latestJobA = a.jobs.sort((job1, job2) => new Date(job2.lastUpdatedAt) - new Date(job1.lastUpdatedAt))[0]; // Sort by most recent lastUpdatedAt
				const latestJobB = b.jobs.sort((job1, job2) => new Date(job2.lastUpdatedAt) - new Date(job1.lastUpdatedAt))[0]; // Sort by most recent lastUpdatedAt

				// Sort based on the most recent lastUpdatedAt
				return getLastUpdatedAtDate(latestJobB) - getLastUpdatedAtDate(latestJobA); // Descending order
			}

			return 0; // Default case (if order is invalid)
		});

		setJobs(_jobs);
	}

	useEffect(() => {
		if (selectedCategory !== "all" || (selectedCategory === "all" && searchedKeyWord === "")) {
			const filters = {
				jobTitle: searchedKeyWord === "" ? null : searchedKeyWord,
				jobCategory: selectedCategory === "" ? null : selectedCategory === "all" ? null : selectedCategory,
				jobLocation: addressLocation.split(",")?.[0] === "" ? null : addressLocation.split(",")?.[0],
				employmentTypes: selectedJobTypes.length === 0 ? null : selectedJobTypes,
				experienceLevels: selectedExperienceLevels.length === 0 ? null : selectedExperienceLevels,
			};

			const updatedJobs = persistJobsData
				.map((item) => {
					const companyJobs = filterJobs(item.jobs, filters);

					if (companyJobs.length === 0) {
						return;
					}

					return { jobs: companyJobs, companyDetails: item.companyDetails };
				})
				.filter(Boolean);

			sortJobs(updatedJobs, selectedSortingType);
		}
	}, [addressLocation, selectedCategory, selectedJobTypes, searchedKeyWord, selectedExperienceLevels]);

	useEffect(() => {
		fetchJobsOnSearch({ page: 1 });
	}, []);

	useEffect(() => {
		if (response && !loadingCategories) {
			setCategories(response.data);
		}
	}, [response, loadingCategories]);

	return (
		<div className="bg-gray-50 min-h-screen">
			<Header isLoggedIn={isUserLoggedIn} variant={isUserLoggedIn ? "applicant" : "default"} />

			<Search
				searchedAddress={searchedAddress}
				jobSearchSource={jobSearchSource}
				selectedCategory={selectedCategory}
				searchedKeyWord={searchedKeyWord}
				setSearchedKeyWord={setSearchKeyword}
				setSelectedCategory={setSelectedCategory}
				fetchJobsOnSearch={fetchJobsOnSearch}
				jobTypes={jobTypes}
				isUserLoggedIn={isUserLoggedIn}
				experienceLevels={experienceLevels}
				setAddressLocation={setAddressLocation}
				addressLocation={addressLocation}
				selectedJobTypes={selectedJobTypes}
				setSelectedJobTypes={setSelectedJobTypes}
				selectedExperienceLevels={selectedExperienceLevels}
				setSelectedExperienceLevels={setSelectedExperienceLevels}
			/>

			<div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 py-8">
				{isLoading ? (
					<div className="mt-5">
						<Loader />
					</div>
				) : (
					<>
						{isUserLoggedIn ? (
							<Tabs defaultValue="allJobs" className="w-full">
								<TabsList className="flex border-b-0">
									<TabsTrigger
										value="allJobs"
										className="ignore-focus-behavior rounded-none text-sm text-gray-700 mr-6"
									>
										All Jobs
									</TabsTrigger>
									<TabsTrigger value="saved" className="ignore-focus-behavior rounded-none text-sm text-gray-700">
										Saved Jobs
									</TabsTrigger>
								</TabsList>

								<TabsContent value="allJobs">
									<div className="flex flex-col lg:flex-row gap-8">
										<div className="lg:w-3/4">
											{/* Title and description with specific height */}
											<div className="h-[88px]">
												{" "}
												{/* Added fixed height container */}
												<h2 className="text-3xl font-bold mb-2 text-gray-900 font-plus-jakarta">
													{selectedCategory !== "all"
														? `${selectedCategory.charAt(0).toUpperCase() + selectedCategory.slice(1)} Jobs${addressLocation ? ` in ${addressLocation}` : ""}`
														: `All Jobs${addressLocation ? ` in ${addressLocation}` : ""}`}
												</h2>
												<div className="flex items-center justify-between">
													<p className="text-sm text-gray-700 font-inter">
														Showing {jobs.length} companies with job openings
													</p>
													<div>
														<Select
															value={selectedSortingType}
															onValueChange={(value) => {
																setSelectedSortingType(value);
																sortJobs(jobs, value);
															}}
														>
															<SelectTrigger unBordered>
																<SelectValue placeholder="Select category" />
															</SelectTrigger>
															<SelectContent>
																<SelectItem value="upcoming"> Ordered by upcoming deadline </SelectItem>
																<SelectItem value="latest"> Ordered by latest jobs </SelectItem>
															</SelectContent>
														</Select>
													</div>
												</div>
											</div>

											<Jobs jobs={jobs} />

											<div className="flex justify-center mt-8 space-x-2">
												<Button
													className="ignore-focus-behavior"
													variant="outline"
													onClick={handlePrevious}
													disabled={pageNumber === 1}
												>
													Previous
												</Button>
												<Button
													className="ignore-focus-behavior"
													variant="outline"
													onClick={handleNext}
													disabled={noNextPage}
												>
													Next
												</Button>
											</div>
										</div>
										<div className="lg:w-1/4 space-y-6 lg:mt-[88px]">
											<Categories
												categories={categories}
												setSelectedCategory={setSelectedCategory}
												selectedCategory={selectedCategory}
												loadingCategories={loadingCategories}
												fetchJobsOnSearch={fetchJobsOnSearch}
											/>
											<Locations
												addresses={addresses}
												setAddressLocation={setAddressLocation}
												addressLocation={addressLocation}
												fetchJobsOnSearch={fetchJobsOnSearch}
												loading={isLoading}
											/>
										</div>
									</div>
								</TabsContent>

								<TabsContent value="saved">
									<div className="flex flex-col lg:flex-row gap-8">
										<div className="lg:w-3/4">
											<div className="h-[70px]">
												<h2 className="text-3xl font-bold mb-2 text-gray-900 font-plus-jakarta">Saved Jobs</h2>
											</div>

											{(isLoadingSavedJobs || isRefetchingSavedJobs) && (
												<div className="animate-pulse">
													{[...new Array(3)].map((_, index) => (
														<div className="h-24 bg-gray-900 opacity-10 mb-3 rounded-md" key={index} />
													))}
												</div>
											)}

											{!(isLoadingSavedJobs || isRefetchingSavedJobs) && savedJobs.length > 0 ? (
												<SavedJobs jobs={savedJobs} />
											) : (
												<div className="text-center py-8 text-gray-500">
													No saved jobs yet. Save jobs to view them here later.
												</div>
											)}
										</div>
										<div className="lg:w-1/4 space-y-6 lg:mt-[88px]" />
									</div>
								</TabsContent>
							</Tabs>
						) : (
							<div className="flex flex-col lg:flex-row gap-8">
								<div className="lg:w-3/4">
									<div className="h-[88px] mb-4 md:mb-0">
										<h2 className="text-3xl font-bold mb-2 text-gray-900 font-plus-jakarta">
											{selectedCategory !== "all"
												? `${selectedCategory.charAt(0).toUpperCase() + selectedCategory.slice(1)} Jobs${addressLocation ? ` in ${addressLocation}` : ""}`
												: `All Jobs${addressLocation ? ` in ${addressLocation}` : ""}`}
										</h2>
										<div className="flex items-center justify-between">
											<p className="text-sm text-gray-700 font-inter">
												Showing {jobs.length} companies with job openings
											</p>
											<div>
												<Select
													value={selectedSortingType}
													onValueChange={(value) => {
														setSelectedSortingType(value);
														sortJobs(jobs, value);
													}}
												>
													<SelectTrigger unBordered>
														<SelectValue placeholder="Select category" />
													</SelectTrigger>
													<SelectContent>
														<SelectItem value="upcoming">Ordered by upcoming deadline</SelectItem>
														<SelectItem value="latest">Ordered by latest jobs </SelectItem>
													</SelectContent>
												</Select>
											</div>
										</div>
									</div>

									<Jobs jobs={jobs} />

									<div className="flex justify-center mt-8 space-x-2">
										<Button
											className="ignore-focus-behavior"
											variant="outline"
											onClick={handlePrevious}
											disabled={pageNumber === 1}
										>
											Previous
										</Button>
										<Button
											className="ignore-focus-behavior"
											variant="outline"
											onClick={handleNext}
											disabled={noNextPage}
										>
											Next
										</Button>
									</div>
								</div>
								<div className="lg:w-1/4 space-y-6 lg:mt-[88px]">
									<Categories
										categories={categories}
										setSelectedCategory={setSelectedCategory}
										selectedCategory={selectedCategory}
										loadingCategories={loadingCategories}
										fetchJobsOnSearch={fetchJobsOnSearch}
									/>
									<Locations
										addresses={addresses}
										setAddressLocation={setAddressLocation}
										addressLocation={addressLocation}
										fetchJobsOnSearch={fetchJobsOnSearch}
										loading={isLoading}
									/>
								</div>
							</div>
						)}
					</>
				)}
			</div>

			{!isLoading && <Footer />}
		</div>
	);
}

export default JobsPage;
