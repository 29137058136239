import React from "react";

import { Button } from "../../../JobsPage/components/Button";
import DashboardVideo from "./DashboardVideo";

function HiringHero() {
	return (
		<div className="px-2 sm:px-4 lg:px-6">
			<section className="hiring-hero">
				<div className="hiring-hero__inner">
					<div className="employers-container">
						<div className="hiring-hero__content">
							<h1 className="hiring-hero__title">Hire Top Talent Faster Without Wasting Time on Manual Processes</h1>
							<p className="hiring-hero__description">
								Streamline your recruitment with AI-powered solutions designed specifically for hiring managers.
							</p>
							<div className="flex items-center justify-center gap-4">
								<Button
									size="lg"
									className="bg-white hover:bg-white/90 text-primary-800 font-medium text-lg px-8 h-16 rounded-full"
									onClick={() => {
										const link = document.createElement("a");
										link.href = "https://forms.gle/YwVU8D6ggxjRRcrJ6";
										link.target = "_blank";
										link.click();
									}}
								>
									Book a Free Demo
								</Button>
								<Button
									size="lg"
									variant="outline"
									className="border-white hover:bg-white/10 text-white hover:text-white font-medium text-lg px-8 h-16 rounded-full bg-transparent"
									onClick={() => {
										window.location.href = "https://hiring.dproz.com/signup";
									}}
								>
									Get Started
								</Button>
							</div>
							<div className="trusted-companies">
								<p className="trusted-companies__text">Trusted by 100+ companies in Tanzania</p>
								<div className="trusted-companies__logos">
									{/* Replace with actual company logos */}
									<div className="company-logo" />
									<div className="company-logo" />
									<div className="company-logo" />
								</div>
							</div>
						</div>

						<div className="hiring-hero__video">
							<DashboardVideo
								videoUrl="https://dproz-website.replit.app/videos/product-demo.mp4"
								posterImage="/images/demo-poster.png"
							/>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default HiringHero;
