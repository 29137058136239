import React, { useEffect } from "react";

import { Avatar, AvatarFallback, AvatarImage } from "../../../../components/Avatar/Avatar";

const profiles = [
	{ id: 1, name: "Daniela", role: "Product Designer", imageUrl: "/images/profiles/tania.png", stage: "screening" },
	{ id: 2, name: "Alex", role: "Software Engineer", imageUrl: "/images/profiles/james.png", stage: "interview" },
	{ id: 3, name: "Maria", role: "UX Designer", imageUrl: "/images/profiles/sarah.png", stage: "hired" },
	{ id: 4, name: "James", role: "Data Scientist", imageUrl: "/images/profiles/khloe.png", stage: "screening" },
	{ id: 5, name: "Priya", role: "Marketing Director", imageUrl: "/images/profiles/fatima.png", stage: "interview" },
	{ id: 6, name: "David", role: "Sales Manager", imageUrl: "/images/profiles/david.png", stage: "hired" },
	{ id: 7, name: "Emma", role: "HR Specialist", imageUrl: "/images/profiles/alex.png", stage: "interview" },
	{ id: 8, name: "Michael", role: "Frontend Developer", imageUrl: "/images/profiles/michael.png", stage: "screening" },
];

const stageColors = {
	screening: "bg-yellow-400",
	interview: "bg-blue-400",
	hired: "bg-green-400",
};

function FloatingFaces() {
	const [hoveredId, setHoveredId] = React.useState(null);
	// eslint-disable-next-line unused-imports/no-unused-vars
	const [currentStage, setCurrentStage] = React.useState("screening");
	// eslint-disable-next-line unused-imports/no-unused-vars
	const [isVisible, setIsVisible] = React.useState(true);

	useEffect(() => {
		const container = document.querySelector(".floating-faces-container");
		if (!container) return;

		const containerRect = container.getBoundingClientRect();
		const gridSize = 5; // Increased grid size for even more spread
		const cellWidth = containerRect.width / gridSize;
		const cellHeight = containerRect.height / gridSize;

		profiles.forEach((profile, index) => {
			const element = document.getElementById(`face-${profile.id}`);
			if (!element) return;

			// Calculate grid position with offset to center the faces
			const row = Math.floor(index / 3); // Back to 3 columns
			const col = index % 3; // Back to 3 columns

			// Add padding to the edges and center the grid
			const gridPaddingX = cellWidth * 0.25; // Reduced padding significantly for wider spread
			const gridPaddingY = cellHeight * 0.5; // Half cell height of padding
			const usableWidth = containerRect.width - gridPaddingX * 2; // Account for padding
			const columnWidth = usableWidth / 3; // Divide usable space into 3 columns

			// Random position within grid cell with more controlled randomness
			const baseX = gridPaddingX + col * columnWidth;
			const baseY = gridPaddingY + row * cellHeight;
			const randomX = baseX + Math.random() * (columnWidth - 100); // Reduced random range
			const randomY = baseY + Math.random() * (cellHeight - 100); // Reduced random range

			element.style.left = `${randomX}px`;
			element.style.top = `${randomY}px`;

			// Add floating animation
			const randomDuration = 4 + Math.random() * 4;
			const randomDelay = Math.random() * -4;
			const randomDistance = 15 + Math.random() * 20;

			element.animate(
				[
					{ transform: "translate(0, 0)" },
					{
						transform: `translate(${Math.random() < 0.5 ? "-" : ""}${randomDistance}px, ${Math.random() < 0.5 ? "-" : ""}${randomDistance}px)`,
					},
					{ transform: "translate(0, 0)" },
				],
				{
					duration: randomDuration * 1000,
					delay: randomDelay * 1000,
					iterations: Infinity,
					direction: "alternate",
					easing: "ease-in-out",
				},
			);
		});
	}, []);

	return (
		<section className="-mt-12">
			<div className="container mx-auto">
				<div className="floating-faces-container relative h-[300px] w-full overflow-visible bg-transparent z-10">
					{profiles.map((profile) => (
						<div
							id={`face-${profile.id}`}
							key={profile.id}
							className="floating-face absolute flex items-center gap-3"
							onMouseEnter={() => setHoveredId(profile.id)}
							onMouseLeave={() => setHoveredId(null)}
						>
							<div
								className={`flex items-center gap-3 transition-all duration-300 ${
									hoveredId === profile.id ? "rounded-full bg-white pl-2 pr-5 py-1.5 border border-gray-200" : ""
								}`}
							>
								<Avatar
									className={`transition-all duration-300 ${hoveredId === profile.id ? "h-12 w-12" : "h-16 w-16"}`}
								>
									<AvatarImage src={profile.imageUrl} alt={`${profile.name}'s profile`} />
									<AvatarFallback>{profile.name[0]}</AvatarFallback>
								</Avatar>
								<div
									className={`flex flex-col items-start transition-all duration-300 ${
										hoveredId === profile.id ? "opacity-100" : "opacity-0 -translate-x-4"
									}`}
								>
									<p className="text-sm font-medium">{profile.role}</p>
									<div className="flex items-center gap-1.5">
										<div className={`h-1.5 w-1.5 rounded-full ${stageColors[profile.stage]}`} />
										<p className="text-sm text-gray-700 capitalize">{profile.stage}</p>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
}

export default FloatingFaces;
