import { googleLogout } from "@react-oauth/google";
import React from "react";
import { Link } from "react-router-dom";

import useIsLoggedIn from "../../hooks/use-IsLoggedIn";

function Header() {
	const storedResumes = localStorage.getItem("resumeList");
	const userLoggedIn = useIsLoggedIn();

	const { firstName } = JSON.parse(localStorage.getItem("profileData"))
		? JSON.parse(localStorage.getItem("profileData"))
		: { firstName: "" };

	const storeNavigationFlow = () => {
		const updatedNavFlowData = {
			previousPage: "",
			previousSubPage: "",
			previousUrl: "",
		};

		localStorage.setItem(`navFlowData${firstName}`, JSON.stringify(updatedNavFlowData));
	};

	const isDropzOpsUser = localStorage.getItem("isDropzOpsUser");

	return (
		<header className="tr-header">
			<nav className="navbar navbar-expand-lg">
				<div className="container">
					<div className="navbar-header">
						<button
							className="navbar-toggler"
							type="button"
							data-toggle="collapse"
							data-target="#navbarNav"
							aria-controls="navbarNav"
							aria-expanded="false"
							aria-label="Toggle navigation"
						>
							<span className="navbar-toggler-icon">
								<i className="fa fa-align-justify" />
							</span>
						</button>
						<Link className="navbar-brand" to="/">
							<img className="img-fluid" src="/images/logo.png" alt="Logo" />
						</Link>
					</div>

					<div className="collapse navbar-collapse" id="navbarNav">
						<ul className="nav navbar-nav">
							{storedResumes && storedResumes.length > 0 ? (
								<li className="d-lg-inline">
									<Link
										onClick={() => {
											storeNavigationFlow();
											window.location.replace("/my-resumes");
										}}
										to="/my-resumes"
									>
										Resumes
									</Link>
								</li>
							) : (
								<>
									<li className="d-none d-lg-inline">
										<Link
											onClick={() => {
												window.location.replace("/resume/select-experience");
											}}
											to="/resume/select-experience"
										>
											Create Resume
										</Link>
									</li>
									<li className="d-none d-lg-inline">
										<Link to="/employer-landing">For Employers</Link>
									</li>
								</>
							)}

							<li className="d-inline d-lg-none">
								<Link
									onClick={() => {
										window.location.replace("/signin");
									}}
									to="/signin"
								>
									Sign In
								</Link>
							</li>
							<li className="d-inline d-lg-none">
								<Link
									onClick={() => {
										window.location.replace("/register");
									}}
									to="/register"
								>
									Register
								</Link>
							</li>
						</ul>
					</div>

					<div className="navbar-right">
						<ul className="sign-in tr-list  order-lg-2 order-1">
							{!userLoggedIn && (
								<>
									<li className="d-none d-lg-inline">
										<Link
											onClick={() => {
												window.location.replace("/signin");
											}}
											to="/signin"
										>
											Sign In
										</Link>
									</li>
									<li className="d-none d-lg-inline">
										<Link
											onClick={() => {
												window.location.replace("/register");
											}}
											to="/register"
										>
											Register
										</Link>
									</li>
								</>
							)}
						</ul>
					</div>
					{userLoggedIn && (
						<div className="navbar-right">
							<div className="header-user order-lg-3 order-3">
								<div className="dropdown tr-change-dropdown ">
									<Link
										data-toggle="dropdown"
										className="img-holder text-dark d-flex align-items-center font-weight-bold"
										to="#"
										aria-expanded="true"
									>
										<span className="fa fa-user mr-2 fs-20 text-primary" />
										{firstName ? `Hi, ${firstName}` : "My Account "}
										<span className="fa fa-angle-down" />
									</Link>
									<ul className="dropdown-menu tr-change tr-list ">
										{userLoggedIn ? (
											<>
												<li>
													<Link to="/settings">My Settings</Link>
												</li>
												{isDropzOpsUser && (
													<>
														<li>
															<Link to="/job-market">Control Center</Link>
														</li>
														<li>
															<Link to="/media-kit">Media Kit</Link>
														</li>
													</>
												)}
												<li>
													<Link to="/signin" onClick={googleLogout()}>
														Log Out
													</Link>
												</li>
											</>
										) : (
											<li>
												<Link to="/register">Register</Link>
											</li>
										)}
									</ul>
								</div>
							</div>
						</div>
					)}
				</div>
			</nav>
		</header>
	);
}

export default Header;
